
.website {

}



section{
	min-height: 100vh;
}

.website {

}


section{
	min-height: 100vh;
}


.inner{
	margin: 0 20px;

	@include breakPoint($breakPoint1){
		margin: 0 30px;
	}

	@include breakPoint($breakPoint2){
		margin: 0 40px;
	}

	@include breakPoint($breakPoint3){

	}
}

.welcome{
	background: url("../img/pic/welcome_bg.jpg") no-repeat 50% 50%;
	background-size: cover;
	position: relative;

	.inner{
		padding: 110px 0 150px;

		@include breakPoint($breakPoint1){
			padding: 150px 0 200px;
		}

		@include breakPoint($breakPoint2){
			padding: 140px 0 240px;
		}

		@include breakPoint($breakPoint3){
			padding: 190px 0 300px;
		}
	}

	&:before{
		//content: '';
		//position: absolute;
		//top: 0;
		//left: 0;
		//right: 0;
		//bottom: 0;
		//background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
		//background: -moz-linear-gradient(-45deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		//background: -webkit-linear-gradient(-45deg, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		//background: linear-gradient(135deg, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
	}

	&:after{
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		height: 20px;
		background: url(../img/svg/adge_top_white.svg) repeat-x 50% 50%;
		background-size: auto 100%;
		z-index: 1;
		bottom: -2px;
	}


	&__subtitle{
		@extend %fontRobotoSlab;
		font-size: 21px;
		color: $colorOrange;
		position: relative;
		margin-bottom: 5px;

		@include breakPoint($breakPoint1){
			font-size: 27px;
			margin-bottom: 10px;
		}

		@include breakPoint($breakPoint2){
			font-size: 21px;
			margin-bottom: 20px;
		}

		@include breakPoint($breakPoint3){
			font-size: 30px;
			margin-bottom: 20px;
		}
	}

	&__title{
		@extend %fontTitle;
		font-size: 60px;
		color: #fff;
		position: relative;
		width: 290px;

		@include breakPoint($breakPoint1){
			font-size: 78px;
			width: 400px;
		}

		@include breakPoint($breakPoint2){
			font-size: 78px;
			width: 780px;
		}

		@include breakPoint($breakPoint3){
			font-size: 100px;
			width: 1000px;
		}
	}

	&__btn{
		position: absolute;
		bottom: 80px;
		left: 20px;
		right: 20px;

		@include breakPoint($breakPoint1){
			bottom: 115px;
			left: 30px;
			right: 30px;
		}

		@include breakPoint($breakPoint2){
			display: none;
		}

		.btn{
			font-size: 18px;
			display: block;
			padding: 14px 20px 15px;

			@include breakPoint($breakPoint1){
				font-size: 20px;
				display: inline-block;
				padding: 17px 40px 19px;
			}
		}
	}

	&__footnote{
		display: none;

		width: 442px;
		height: 117px;
		position: absolute;
		background: url("../img/svg/welcome_footnote.svg") no-repeat 50% 50%;
		background-size: 100% 100%;
		box-sizing: border-box;
		padding: 38px 50px 0 48px;
		color: $colorOrange;
		font-size: 16px;
		left: 40px;
		bottom: 113px;
		font-weight: 600;

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 37px;
			left: 34px;
			width: 10px;
			height: 10px;
			background: url("../img/svg/star.svg") no-repeat 50% 50%;
			background-size: contain;

			@include breakPoint($breakPoint3){
				top: 54px;
				left: 38px;
				width: 12px;
				height: 12px;
			}

		}


		@include breakPoint($breakPoint2){
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 24px;
			width: 634px;
			height: 168px;
			padding: 53px 60px 0 58px;
		}

	}
}





.platform{
	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

	}


	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 20px;
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 60px;
		}
	}


	&__places{}

	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 100px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 110px;
		}

		@include breakPoint($breakPoint2){
			display: none;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__content{
		position: relative;
		font-size: 0;
		//overflow: hidden;

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}

	&__list{

	}

	&__item{
		position: relative;
		min-height: 100vh;
		box-sizing: border-box;
		padding: 85px 0 300px;

		@include breakPoint($breakPoint2){
			width: 50%;
			display: inline-block;
			vertical-align: middle;
			min-height: calc(100vh - 190px);
		}

		@include breakPoint($breakPoint3){
			padding-bottom: 400px;
			min-height: calc(100vh - 253px);

		}

		&-map{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $colorGreyLight no-repeat 50% 50%;
			background-size: cover;
			overflow: hidden;

			&:before{
				@include breakPoint($breakPoint2){
					.platform__item:first-child &{
						content: '';
						position: absolute;
						display: block;
						width: 2px;
						height: 100%;
						top: -30px;
						right: -1px;
						background: $colorOrange;
						z-index: 2;
					}
				}
			}
		}

		&-info{
			background: #000;
			padding: 10px 20px;
			position: relative;

			@include breakPoint($breakPoint1){
				padding: 20px 30px;
			}

			@include breakPoint($breakPoint2){
				position: absolute;
				left: 13px;
				background: url("../img/pic/text_bg_black.png") no-repeat 50% 50%;
				background-size: 100% 100%;
				width: 465px;
				height: 137px;
				box-sizing: border-box;
				padding: 20px 30px 0 25px;
				top: 30px;
			}

			@include breakPoint($breakPoint3){
				width: 700px;
				height: 206px;
				padding: 35px 30px 0 40px;
				left: 20px;
			}

			&:before,
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				height: 20px;
				background: repeat-x 50% 50%;
				background-size: auto 100%;

				@include breakPoint($breakPoint2){
					display: none;
				}
			}
			&:before{
				bottom: calc(100% - 2px);
				background-image: url("../img/svg/adge_top_black.svg");
			}
			&:after{
				top: calc(100% - 2px);
				background-image: url("../img/svg/adge_bottom_black.svg");
			}


			&-title{
				@extend %fontTitle;
				font-size: 24px;
				color: $colorOrange;
				margin-right: 140px;
				line-height: 1.3;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					font-size: 31px;
					margin-bottom: 10px;
				}

				@include breakPoint($breakPoint2){
					font-size: 28px;
					margin-right: 0;
					margin-bottom: 3px;
				}

				@include breakPoint($breakPoint3){
					font-size: 40px;
				}
			}

			&-address{
				@extend %fontRobotoSlab;
				font-size: 16px;
				color: #fff;
				margin-bottom: 10px;

				@include breakPoint($breakPoint1){
					font-size: 21px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					margin-bottom: 5px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
					margin-bottom: 10px;
				}
			}

			&-text{
				font-size: 12px;
				color: $colorGreyLight;
				margin-top: 7px;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					margin-top: 2px;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}


		&-control{
			position: absolute;
			bottom: 70px;
			left: 20px;
			right: 20px;
			padding-bottom: 5px;
			display: inline-block;
			font-size: 0;
			//white-space: nowrap;

			@include breakPoint($breakPoint1){
				bottom: 90px;
				left: 30px;
				right: 30px;
				white-space: nowrap;
			}

			@include breakPoint($breakPoint2){
				left: 40px;
				right: 40px;
			}

			@include breakPoint($breakPoint3){
				bottom: 130px;
			}


			.btn{

				&_grad{
					width: 100%;
					padding: 15px 10px 16px;
					vertical-align: top;
					margin-right: 9px;
					font-size: 16px;
					margin-bottom: 10px;


					@include breakPoint($breakPoint3){
						margin-right: 20px;
					}
				}

				&_white{
					width: 100%;
					padding: 15px 10px 16px;
					white-space: normal;
					vertical-align: top;
					font-size: 16px;
				}

				@include breakPoint($breakPoint1){
					font-size: 20px;
					padding: 18px 20px 18px;
					width: auto;
					margin-bottom: 0;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					padding: 11px 20px 10px;
					width: auto;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
					padding: 16px 30px 15px;
				}
			}



		}
	}
}





.programm{
	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

		@include breakPoint($breakPoint3){
			padding-top: 135px;
		}

	}


	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 20px;
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 60px;
		}
	}


	&__content{
		position: relative;
		font-size: 0;
		background: #F1F1F1;

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}


	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 90px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 110px;
		}

		@include breakPoint($breakPoint2){
			display: none;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__tabs{
		display: none;
		overflow: hidden;

		.mCSB_container{
			margin-right: 0;
		}

		@include breakPoint($breakPoint2){
			width: 20%;
			display: inline-block;
			vertical-align: top;
			padding-top: 25px;
		}

		@include breakPoint($breakPoint3){
			width: 20%;
			display: inline-block;
			vertical-align: top;
			padding-top: 30px;
		}


		&-item{
			cursor: pointer;
			position: relative;
			margin: 35px 0 35px 70px;

			@include breakPoint($breakPoint3){
				margin: 37px 0 37px 110px;
			}

			&:before{
				content: '';
				position: absolute;
				background: url("../img/pic/text_bg_black.png") no-repeat 50% 50%;
				background-size: 100% 100%;
				top: -19px;
				left: -55px;
				right: -160px;
				bottom: -27px;
				display: none;

				@include breakPoint($breakPoint3){
					top: -25px;
					left: -80px;
					right: -87px;
					bottom: -28px;
				}
			}

			&.is-active{
				&:before{
					display: block;
				}
			}
		}

		&-info{
			position: relative;
		}

		&-num{
			font-size: 40px;
			@extend %fontTitle;
			position: absolute;
			top: -4px;
			left: -30px;

			@include breakPoint($breakPoint3){
				font-size: 50px;
				left: -50px;
			}

			.is-active &{
				color: $colorOrange;
			}

		}
		&-title{
			font-size: 18px;
			@extend %fontTitle;
			margin-bottom: 5px;

			@include breakPoint($breakPoint3){
				font-size: 24px;
			}

			.is-active &{
				color: $colorOrange;
			}
		}
		&-text{
			font-size: 12px;
			color: $colorGrey;
			margin-bottom: 5px;

			@include breakPoint($breakPoint3){
				font-size: 14px;
			}

			span{
				color: #000;
			}

			.is-active &{
				color: #fff;

				span{
					color: $colorOrange;
				}
			}
		}
	}

	&__list{

		@include breakPoint($breakPoint2){
			width: 80%;
			display: inline-block;
			vertical-align: top;
		}

	}

	&__item{
		position: relative;

		&-picture{
			display: none;
			background: no-repeat 50% 50%;
			background-size: cover;

			@include breakPoint($breakPoint2){
				display: block;
				background-color: $colorGrey;
				position: absolute;
				top: 0;
				left: 0;
				width: 25%;
				bottom: 0;
			}


			&-age{
				font-size: 30px;
				@extend %fontTitle;
				color: $colorOrange;
				position: absolute;
				height: 58px;
				line-height: 58px;
				min-width: 60px;
				text-align: center;
				padding: 0 15px;
				box-sizing: border-box;
				right: 0;
				bottom: 90px;
				background: url("../img/svg/age.svg") no-repeat 0 50%;
				background-size: auto 100%;

				@include breakPoint($breakPoint3){
					font-size: 40px;
					height: 80px;
					line-height: 80px;
					min-width: 90px;
					bottom: 100px;
				}
			}
		}

		&-info{
			padding: 88px 20px 140px;
			box-sizing: border-box;
			min-height: 100vh;
			position: relative;

			@include breakPoint($breakPoint1){
				padding: 120px 30px 170px;
			}

			@include breakPoint($breakPoint2){
				margin-left: 25%;
				padding: 50px 40px 90px 40px;
				min-height: calc(100vh - 190px);
			}

			@include breakPoint($breakPoint3){
				padding: 50px 40px 100px 60px;
				min-height: calc(100vh - 280px);
			}
		}

		&-title{
			@extend %fontTitle;
			font-size: 24px;
			color: $colorOrange;
			margin-bottom: 15px;
			margin-right: 110px;

			@include breakPoint($breakPoint1){
				font-size: 31px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPoint2){
				font-size: 30px;
			}

			@include breakPoint($breakPoint3){
				font-size: 40px;
			}
		}

		&-list{
			font-size: 12px;
			margin-bottom: 20px;

			@include breakPoint($breakPoint1){
				font-size: 16px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPoint2){
				font-size: 12px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPoint3){
				font-size: 14px;
			}

			ul{

				@include breakPoint($breakPoint2){
					columns: 300px 2;
					column-gap: 20px;
				}
			}

			li{
				padding-left: 21px;
				position: relative;
				margin: 0 0 5px 0;

				@include breakPoint($breakPoint1){
					margin: 0 0 10px 0;
				}

				&:before{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 10px;
					height: 10px;
					background: url("../img/svg/star.svg") no-repeat 50% 50%;
					background-size: contain;

					@include breakPoint($breakPoint1){
						width: 13px;
						height: 13px;
						top: -1px;
					}

					@include breakPoint($breakPoint2){
						width: 10px;
						height: 10px;
					}

					@include breakPoint($breakPoint3){
						width: 13px;
						height: 13px;
						top: -1px;
					}

				}
			}
		}

		&-course{
			margin-bottom: 20px;

			@include breakPoint($breakPoint2){
				margin-bottom: 30px;
			}

			&-title{
				font-size: 14px;
				@extend %fontRobotoSlab;
				margin-bottom: 10px;

				@include breakPoint($breakPoint1){
					font-size: 21px;
					margin-bottom: 25px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
				}
			}

			&-list{
				white-space: nowrap;
				//overflow-x: auto;
				font-size: 0;
				margin: 0 -20px;
				//padding: 0 20px;

				.slick-arrow{

					@include breakPoint(0, $breakPoint2 - 1px){
						display: none!important;
					}

					position: absolute;
					top: -45px;
					right: 0;
					width: 28px;
					height: 28px;
					fill: $colorGreyLight;
					display: inline-block;
					cursor: pointer;

					&_prev{
						transform: rotate(-90deg);
						right: 95px;

						@include breakPoint($breakPoint3){
							right: 115px;
						}
					}

					&_next{
						transform: rotate(90deg);
						margin-top: -2px;
						right: 0;
					}

					&:hover{
						fill: $colorOrange;
					}

					@include breakPoint($breakPoint3){
						width: 36px;
						height: 36px;
					}

					&.slick-disabled{
						fill: $colorGreyLight!important;
						cursor: default;
						opacity: 0.5;
					}

				}

				.slick-track{
					margin: 0;
				}

				@include breakPoint($breakPoint1){
					margin: 0 -30px 110px;
					//padding: 0 30px;
				}

				@include breakPoint($breakPoint2){
					margin: 0;
					//padding: 0;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
			&-item{
				white-space: normal;
				display: inline-block;
				vertical-align: top;

				padding-left: 20px;
				//margin-left: 10px;

				@include breakPoint(0, $breakPoint1 - 1px){
					width: 210px!important;
				}

				@include breakPoint($breakPoint1){
					padding-left: 30px;
					//margin-left: 20px;
				}

				@include breakPoint($breakPoint1, $breakPoint2 - 1px){
					width: 180px!important;
					//margin-left: 20px;
				}

				@include breakPoint($breakPoint2){
					padding-left: 0;
					padding-right: 20px;
					box-sizing: border-box;
				}

				@include breakPoint($breakPoint3){
					//margin-left: 30px;
				}


			}
			&-image{
				width: 100px;
				float: left;

				@include breakPoint($breakPoint1){
					width: 150px;
					height: 121px;
					float: none;
					margin: 0 auto 10px;
				}

				@include breakPoint($breakPoint2){
					width: 120px;
					height: 97px;
				}

				@include breakPoint($breakPoint3){
					width: 182px;
					height: 146px;
				}

				img{
					width: 100%;
					display: block;
				}
			}
			&-name{
				@extend %fontRobotoSlab;
				font-size: 14px;
				color: $colorOrange;
				margin-left: 110px;
				width: 100px;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					margin-left: 0;
					width: auto;
					font-size: 18px;
					margin-bottom: 10px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
				}
			}
			&-text{
				font-size: 12px;
				margin-left: 110px;
				width: 100px;

				@include breakPoint($breakPoint1){
					margin-left: 0;
					width: auto;
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
				}
			}
		}

		&-price{
			font-size: 0;

			&-item{

				@include breakPoint($breakPoint2){
					display: inline-block;
					vertical-align: middle;
					width: 250px;
				}

				@include breakPoint($breakPoint3){
					width: 425px;
				}
			}

			&-name{
				@extend %fontRobotoSlab;
				font-size: 12px;
				display: inline-block;
				vertical-align: baseline;
				width: calc(100% - 115px);
				margin-bottom: 7px;

				@include breakPoint($breakPoint1){
					font-size: 16px;
					width: calc(100% - 280px);
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					display: block;
					float: left;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
					width: calc(100% - 260px);
					line-height: 1.4;
				}
			}
			&-pay{
				@extend %fontRobotoSlab;
				font-size: 20px;
				font-weight: 600;
				color: $colorOrange;
				display: inline-block;
				vertical-align: baseline;

				@include breakPoint($breakPoint1){
					font-size: 26px;
				}

				@include breakPoint($breakPoint2){
					font-size: 20px;
					display: block;
					margin: 4px 0 4px 105px;
				}

				@include breakPoint($breakPoint3){
					font-size: 30px;
					margin: 0px 0 5px 170px;
				}
			}
			&-footnote{
				font-size: 12px;
				color: $colorGrey;
				display: inline-block;
				vertical-align: baseline;

				@include breakPoint($breakPoint1){
					font-size: 12px;
					margin-left: 10px;
				}

				@include breakPoint($breakPoint2){
					font-size: 10px;
					display: block;
					margin-left: 105px;
				}

				@include breakPoint($breakPoint3){
					font-size: 12px;
					margin-left: 170px;
				}
			}
		}

		&-controls{
			position: absolute;
			bottom: 70px;
			left: 20px;
			right: 20px;
			padding-bottom: 5px;
			display: inline-block;
			font-size: 0;
			white-space: nowrap;

			@include breakPoint($breakPoint1){
				bottom: 90px;
				left: 30px;
				right: 30px;
			}

			@include breakPoint($breakPoint2){
				display: inline-block;
				position: static;
				width: calc(100% - 500px);
			}

			@include breakPoint($breakPoint3){
				width: calc(100% - 850px);
				margin-left: -15px;
			}

			.btn{
				padding: 15px 20px 16px;
				vertical-align: middle;
				font-size: 16px;

				&_grad{
				}

				&_white{
					margin-right: 9px;

					@include breakPoint($breakPoint1){
						margin-right: 20px;
					}

					@include breakPoint($breakPoint2){
						display: none;
					}
				}

				@include breakPoint($breakPoint1){
					font-size: 20px;
					padding: 18px 40px 18px;
					width: auto;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					padding: 10px 20px 11px;
					width: auto;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
					padding: 15px 30px 16px;
				}
			}
		}
	}
}





.license{
	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

		@include breakPoint($breakPoint3){
			padding-top: 140px;
		}

	}


	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 20px;
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 60px;
		}
	}

	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 90px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 110px;
		}

		@include breakPoint($breakPoint2){
			display: none;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__content{
		position: relative;
		font-size: 0;
		background: #f5f5f5;


		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}

	&__list{

	}

	&__item{
		position: relative;
		min-height: 100vh;
		box-sizing: border-box;
		padding: 88px 20px 85px;
		background: #F1F1F1;
		width: 100vw!important;

		@include breakPoint($breakPoint1){
			padding: 190px 30px 85px;
			width: 345px!important;

			&_odd{
				background: #f5f5f5;
			}
		}

		@include breakPoint($breakPoint2){
			width: 20%!important;
			display: inline-block;
			vertical-align: top;
			padding: 40px 20px 70px;
			min-height: calc(100vh - 190px);
		}

		@include breakPoint($breakPoint3){
			padding: 60px 40px 100px;
			min-height: calc(100vh - 285px);
		}

		&-part{
			font-size: 24px;
			@extend %fontTitle;
			margin-right: 120px;


			@include breakPoint($breakPoint1){
				font-size: 31px;
				margin-right: 0;
			}

			@include breakPoint($breakPoint2){
				font-size: 30px;
			}

			@include breakPoint($breakPoint3){
				font-size: 40px;
			}


			span{
				color: $colorOrange;

				@include breakPoint($breakPoint2){
					font-size: 40px;
				}

				@include breakPoint($breakPoint3){
					font-size: 50px;
				}
			}
		}

		&-image{
			width: 241px;
			margin: 0 auto;
			min-height: calc(100vh - 310px);
			display: flex;
			align-items: center;
			justify-content: center;

			@include breakPoint($breakPoint1){
				width: 300px;
				height: 242px;
				min-height: calc(100vh - 620px);
			}

			@include breakPoint($breakPoint2){
				width: 166px;
				min-height: calc(100vh - 550px);
				height: 200px;
			}

			@include breakPoint($breakPoint3){
				width: 241px;
				min-height: calc(100vh - 690px);
			}

			img{
				width: 100%;
			}
		}

		&-title{
			font-size: 14px;
			@extend %fontRobotoSlab;
			color: $colorOrange;
			margin-bottom: 10px;

			@include breakPoint($breakPoint1){
				font-size: 18px;
			}

			@include breakPoint($breakPoint2){
				font-size: 14px;
			}

			@include breakPoint($breakPoint3){
				font-size: 16px;
			}
		}

		&-text{
			font-size: 12px;
			line-height: 1.5;

			@include breakPoint($breakPoint1){
				font-size: 16px;
			}

			@include breakPoint($breakPoint2){
				font-size: 12px;
			}

			@include breakPoint($breakPoint3){
				font-size: 14px;
			}

			a{
				color: $colorOrange;

				&:hover{
					text-decoration: underline;
				}
			}


			li{
				position: relative;
				padding-left: 15px;


				&:before{
					content: '';
					display: block;
					position: absolute;
					top: 2px;
					left: 0px;
					width: 10px;
					height: 10px;
					background: url("../img/svg/star.svg") no-repeat 50% 50%;
					background-size: contain;

					@include breakPoint($breakPoint1){
						top: 6px;
					}

					@include breakPoint($breakPoint2){
						top: 2px;
					}

					@include breakPoint($breakPoint3){
						width: 12px;
						height: 12px;
					}

				}
			}
		}

	}
}





.command{
	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

		@include breakPoint($breakPoint3){
			padding-top: 135px;
		}

	}

	&__head{

		@include breakPoint($breakPoint2){
			margin-bottom: 20px;
		}
	}

	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			display: table-cell;
			vertical-align: top;
			padding: 0 30px;
			white-space: nowrap;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 60px;
		}
	}


	&__info{
		color: $colorGrey;
		font-size: 12px;
		padding: 80px 20px 20px;

		@include breakPoint($breakPoint1){
			font-size: 16px;
			line-height: 1.5;
			padding: 105px 30px 30px;
		}

		@include breakPoint($breakPoint2){
			display: table-cell;
			font-size: 12px;
			vertical-align: middle;
			padding: 10px 30px 0 0;
			line-height: 1.2;
		}

	}

	&__content{
		position: relative;
		font-size: 0;
		background: #F1F1F1;

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}


	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 40px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 67px;
		}

		@include breakPoint($breakPoint2){
			display: none;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__tabs{
		display: none;
		overflow: hidden;

		.mCSB_container{
			margin-right: 0;
		}

		@include breakPoint($breakPoint2){
			width: 20%;
			display: inline-block;
			vertical-align: top;
			padding-top: 25px;

			max-height: calc(100vh - 285px);
			overflow: auto;

		}

		@include breakPoint($breakPoint3){
			width: 20%;
			display: inline-block;
			vertical-align: top;
			padding-top: 30px;
			max-height: calc(100vh - 350px);
		}


		&-item{
			cursor: pointer;
			position: relative;
			margin: 30px 0 30px 70px;

			@include breakPoint($breakPoint3){
				margin: 37px 0 37px 110px;
			}

			&:before{
				content: '';
				position: absolute;
				background: url("../img/pic/text_bg_black.png") no-repeat 50% 50%;
				background-size: 100% 100%;
				top: -19px;
				left: -60px;
				right: -100px;
				bottom: -23px;
				display: none;

				@include breakPoint($breakPoint3){
					top: -25px;
					left: -80px;
					right: -87px;
					bottom: -28px;
				}
			}

			&.is-active{
				&:before{
					display: block;
				}
			}
		}

		&-info{
			position: relative;
		}

		&-photo{

			position: absolute;
			top: -2px;
			left: -45px;
			width: 36px;
			height: 36px;

			@include breakPoint($breakPoint3){
				width: 50px;
				height: 50px;
				left: -65px;
				top: 0;
			}


			img{
				width: 100%;
				height: 100%;
			}

		}
		&-title{
			font-size: 18px;
			@extend %fontTitle;
			margin-bottom: 5px;

			@include breakPoint($breakPoint3){
				font-size: 24px;
			}

			.is-active &{
				color: $colorOrange;
			}
		}
		&-text{
			font-size: 12px;
			color: $colorGrey;
			margin-bottom: 5px;

			@include breakPoint($breakPoint3){
				font-size: 14px;
			}

			span{
				color: #000;
			}

			.is-active &{
				color: #fff;

				span{
					color: $colorOrange;
				}
			}
		}
	}

	&__list{

		@include breakPoint($breakPoint2){
			width: 80%;
			display: inline-block;
			vertical-align: top;
		}

	}

	&__item{
		position: relative;

		&-picture{
			display: none;
			background: no-repeat 50% 50%;
			background-size: cover;

			@include breakPoint($breakPoint2){
				display: block;
				background-color: $colorGrey;
				position: absolute;
				top: 0;
				left: 0;
				width: 25%;
				bottom: 0;
			}


			&-age{
				font-size: 30px;
				@extend %fontTitle;
				color: $colorOrange;
				position: absolute;
				height: 58px;
				line-height: 58px;
				min-width: 60px;
				text-align: center;
				padding: 0 15px;
				box-sizing: border-box;
				right: 0;
				bottom: 90px;
				background: url("../img/svg/age.svg") no-repeat 0 50%;
				background-size: auto 100%;

				@include breakPoint($breakPoint3){
					font-size: 40px;
					height: 80px;
					line-height: 80px;
					min-width: 90px;
					bottom: 100px;
				}
			}
		}

		&-info{
			padding: 35px 20px 115px;
			box-sizing: border-box;
			position: relative;

			@include breakPoint($breakPoint1){
				padding: 45px 30px 80px;
			}

			@include breakPoint($breakPoint2){
				margin-left: 25%;
				padding: 50px 40px 90px 40px;
				min-height: calc(100vh - 190px);
			}

			@include breakPoint($breakPoint3){
				padding: 50px 40px 100px 60px;
				min-height: calc(100vh - 280px);
			}
		}

		&-head{
			overflow: hidden;
			margin-right: 110px;
			white-space: nowrap;
			margin-bottom: 15px;


			@include breakPoint($breakPoint1){
				margin-bottom: 20px;
			}
		}

		&-photo{
			width: 50px;
			height: 50px;
			overflow: hidden;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;

			img{
				width: 100%;
				height: 100%;
			}

			@include breakPoint($breakPoint1){
				width: 65px;
				height: 65px;
			}

			@include breakPoint($breakPoint2){
				display: none;
			}
		}

		&-title{
			@extend %fontTitle;
			display: inline-block;
			vertical-align: middle;
			font-size: 24px;
			color: $colorOrange;
			margin-left: 10px;
			white-space: normal;

			@include breakPoint($breakPoint1){
				font-size: 31px;
				margin-left: 20px;
			}

			@include breakPoint($breakPoint2){
				font-size: 30px;
				margin-left: 0;
			}

			@include breakPoint($breakPoint3){
				font-size: 40px;
			}
		}

		&-list{
			font-size: 12px;
			margin-bottom: 20px;

			@include breakPoint($breakPoint1){
				font-size: 16px;
				margin-bottom: 70px;
			}

			@include breakPoint($breakPoint2){
				font-size: 12px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPoint3){
				font-size: 14px;
			}

			ul{

				@include breakPoint($breakPoint2){
					//columns: 300px 2;
					//column-gap: 20px;
				}
			}

			li{
				position: relative;
				margin: 0 0 5px 0;

				@include breakPoint($breakPoint2){
				}
			}
		}

		&-course{
			margin-bottom: 20px;

			@include breakPoint($breakPoint2){
				margin-bottom: 30px;
			}

			&-title{
				font-size: 14px;
				@extend %fontRobotoSlab;
				margin-bottom: 10px;

				@include breakPoint($breakPoint1){
					font-size: 21px;
					margin-bottom: 25px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
				}
			}

			&-list{
				white-space: nowrap;
				//overflow-x: auto;
				font-size: 0;
				margin: 0 -20px;
				//padding: 0 20px;

				.slick-arrow{

					@include breakPoint(0, $breakPoint2 - 1px){
						display: none!important;
					}

					position: absolute;
					top: -45px;
					right: 0;
					width: 28px;
					height: 28px;
					fill: $colorGreyLight;
					display: inline-block;
					cursor: pointer;

					&_prev{
						transform: rotate(-90deg);
						right: 95px;

						@include breakPoint($breakPoint3){
							right: 115px;
						}
					}

					&_next{
						transform: rotate(90deg);
						margin-top: -2px;
						right: 0;
					}

					&:hover{
						fill: $colorOrange;
					}

					@include breakPoint($breakPoint3){
						width: 36px;
						height: 36px;
					}

					&.slick-disabled{
						fill: $colorGreyLight!important;
						cursor: default;
						opacity: 0.5;
					}
				}

				.slick-track{
					margin: 0;
				}
				@include breakPoint($breakPoint1){
					margin: 0 -30px 110px;
					//padding: 0 30px;
				}

				@include breakPoint($breakPoint2){
					margin: 0;
					//padding: 0;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
			&-item{
				white-space: normal;
				display: inline-block;
				vertical-align: top;

				padding-left: 20px;
				//margin-left: 10px;

				@include breakPoint(0, $breakPoint1 - 1px){
					width: 210px!important;
				}

				@include breakPoint($breakPoint1){
					padding-left: 30px;
					//margin-left: 20px;
				}

				@include breakPoint($breakPoint1, $breakPoint2 - 1px){
					width: 180px!important;
					//margin-left: 20px;
				}

				@include breakPoint($breakPoint2){
					padding-left: 0;
					padding-right: 20px;
					box-sizing: border-box;
				}

				@include breakPoint($breakPoint3){
					//margin-left: 30px;
				}

			}
			&-image{
				width: 100px;
				float: left;

				@include breakPoint($breakPoint1){
					width: 150px;
					height: 121px;
					float: none;
					margin: 0 auto 10px;
				}

				@include breakPoint($breakPoint2){
					width: 120px;
					height: 97px;
				}

				@include breakPoint($breakPoint3){
					width: 182px;
					height: 146px;
				}

				img{
					width: 100%;
					display: block;
				}
			}
			&-name{
				@extend %fontRobotoSlab;
				font-size: 14px;
				color: $colorOrange;
				margin-left: 110px;
				width: 100px;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					margin-left: 0;
					width: auto;
					font-size: 18px;
					margin-bottom: 10px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
				}
			}
			&-text{
				font-size: 12px;
				margin-left: 110px;
				width: 100px;

				@include breakPoint($breakPoint1){
					margin-left: 0;
					width: auto;
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
				}
			}
		}

		&-controls{
			position: absolute;
			bottom: 70px;
			left: 20px;
			right: 20px;
			padding-bottom: 5px;
			display: inline-block;
			font-size: 0;
			white-space: nowrap;

			@include breakPoint($breakPoint1){
				bottom: 90px;
				left: 30px;
				right: 30px;
			}

			@include breakPoint($breakPoint2){
				display: inline-block;
				position: static;
				width: calc(100% - 500px);
			}

			@include breakPoint($breakPoint3){
				width: calc(100% - 850px);
				margin-left: -15px;
			}

			.btn{
				padding: 15px 20px 16px;
				vertical-align: middle;
				font-size: 16px;

				&_grad{

					@include breakPoint(0, $breakPoint1 - 1px){
						width: 209px;
						white-space: normal;
						padding: 6px 20px;
					}
				}

				&_white{
					margin-right: 9px;

					@include breakPoint($breakPoint1){
						margin-right: 20px;
					}

					@include breakPoint($breakPoint2){
						display: none;
					}
				}

				@include breakPoint($breakPoint1){
					font-size: 20px;
					padding: 18px 40px 18px;
					width: auto;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					padding: 10px 20px 11px;
					width: auto;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
					padding: 15px 30px 16px;
				}
			}
		}
	}
}



.feedback{
	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

		@include breakPoint($breakPoint3){
			padding-top: 140px;
		}

	}


	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 20px;
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 35px;
		}
	}

	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 90px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 130px;
		}

		@include breakPoint($breakPoint2){
			top: -60px;
		}

		@include breakPoint($breakPoint3){
			top: -95px;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__content{
		position: relative;
		font-size: 0;

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}

	&__list{
		background: #F1F1F1;
	}

	&__item{
		position: relative;
		min-height: 100vh;
		box-sizing: border-box;
		padding: 83px 20px 85px;
		width: 100vw!important;

		@include breakPoint($breakPoint1){
			padding: 95px 30px 85px;

			&_odd{
				background: #f5f5f5;
			}
		}

		@include breakPoint($breakPoint2){
			width: 512px!important;
			padding: 20px 30px 70px;
			min-height: 0;
		}

		@include breakPoint($breakPoint3){
			width: 734px!important;
			padding: 60px 40px 100px;
			min-height: calc(100vh - 285px);
		}


		&-info{
			background: #fff;
			position: relative;
			padding: 5px 20px;
			margin: 0 -20px;

			@include breakPoint($breakPoint1){
				padding: 20px 30px;
				margin: 0 -30px;
			}

			@include breakPoint($breakPoint2){
				margin: 0;
				position: relative;
				left: 13px;
				background: url("../img/pic/text_bg_white.png") no-repeat 50% 50%;
				background-size: 100% 100%;
				width: 356px;
				box-sizing: border-box;
				padding: 20px 82px 22px 31px;
				min-height: 115px;
				margin-left: -30px;
			}

			@include breakPoint($breakPoint3){
				width: 530px;
				padding: 35px 50px 41px 40px;
			}


			&:before,
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				height: 20px;
				background: repeat-x 50% 50%;
				background-size: auto 100%;
				z-index: 1;

				@include breakPoint($breakPoint2){
					display: none;
				}

			}
			&:before{
				top: -19px;
				background-image: url("../img/svg/adge_top_white.svg");
			}
			&:after{
				bottom: -19px;
				background-image: url("../img/svg/adge_bottom_white.svg");
			}

			&-photo{
				width: 50px;
				height: 50px;
				border-radius: 50%;
				overflow: hidden;
				margin-bottom: 10px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;

				@include breakPoint($breakPoint1){
					width: 65px;
					height: 65px;
				}

				@include breakPoint($breakPoint2){
					width: 36px;
					height: 36px;
					position: absolute;
					top: 21px;
					left: 20px;

					& ~ * {
						margin-left: 40px;
					}
				}

				@include breakPoint($breakPoint3){
					width: 50px;
					height: 50px;
					top: 36px;
					left: 20px;
				}

				img{
					width: 100%;
					height: 100%;
				}
			}
			&-name{
				font-size: 24px;
				@extend %fontTitle;
				text-transform: uppercase;
				vertical-align: middle;
				display: inline-block;
				width: calc(100% - 170px);
				margin-bottom: 10px;

				@include breakPoint($breakPoint1){
					font-size: 31px;
				}

				@include breakPoint($breakPoint2){
					font-size: 18px;
					width: auto;
				}

				@include breakPoint($breakPoint3){
					font-size: 40px;
				}

			}
			&-title{
				font-size: 14px;
				@extend %fontRobotoSlab;
				color: $colorOrange;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
					display: inline-block;
				}

			}
			&-text{
				font-size: 12px;
				color: $colorGreyLight;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
					display: inline-block;
					margin-left: 20px;
				}
			}
		}

		&-body{
			padding: 20px 0;
			max-height: calc(100vh - 400px);
			min-height: 100px;
			margin-bottom: 20px;
			overflow: hidden;

			@include breakPoint($breakPoint1){
				max-height: calc(100vh - 490px);
				padding: 40px 0 10px;
			}

			@include breakPoint($breakPoint2){
				overflow: auto;
				padding: 10px 0 10px;
				max-height: calc(100vh - 470px);
			}

			@include breakPoint($breakPoint3){
				max-height: calc(100vh - 650px);
			}

			&-title{
				font-size: 14px;
				@extend %fontRobotoSlab;
				color: $colorOrange;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					font-size: 18px;
					margin-bottom: 15px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
				}
			}
			&-text{
				font-size: 12px;
				color: $colorGrey;
				line-height: 1.5;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
				}
			}
		}

		&-btn{

			.btn{
				display: block;

				@include breakPoint($breakPoint1){
					display: inline-block;
				}

				@include breakPoint($breakPoint2){
					display: none;
				}

			}
		}

		&-date{
			display: none;

			@include breakPoint($breakPoint2){
				display: block;
				font-size: 12px;
				color: $colorGrey;
			}

		}

	}
}

.vehicle{
	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

		@include breakPoint($breakPoint3){
			padding-top: 140px;
		}

	}


	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 20px;
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 35px;
		}
	}

	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 86px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 130px;
		}

		@include breakPoint($breakPoint2){
			top: -60px;
		}

		@include breakPoint($breakPoint3){
			top: -95px;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__content{
		position: relative;
		font-size: 0;

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}

	&__list{
		background: #F1F1F1;
	}

	&__item{
		position: relative;
		min-height: 100vh;
		box-sizing: border-box;
		padding: 83px 20px 85px;
		width: 100vw!important;

		@include breakPoint($breakPoint1){
			padding: 200px 30px 85px;
			width: 675px!important;

			&_odd{
				background: #f5f5f5;
			}
		}

		@include breakPoint($breakPoint2){
			width: 512px!important;
			padding: 40px 30px 70px;
			min-height: 0;
		}

		@include breakPoint($breakPoint3){
			width: 734px!important;
			padding: 60px 40px 100px;
			min-height: calc(100vh - 285px);
		}



		&-title{
			font-size: 24px;
			color: $colorOrange;
			@extend %fontTitle;
			margin-bottom: 10px;
			margin-right: 120px;

			@include breakPoint($breakPoint1){
				font-size: 31px;
			}

			@include breakPoint($breakPoint2){
				font-size: 30px;
			}

			@include breakPoint($breakPoint3){
				font-size: 40px;
			}
		}

		&-text{
			font-size: 12px;
			color: $colorGrey;

			@include breakPoint($breakPoint1){
				font-size: 16px;
			}

			@include breakPoint($breakPoint2){
				font-size: 12px;
			}

			@include breakPoint($breakPoint3){
				font-size: 14px;
			}
		}

		&-image{
			margin: 0 auto;
			min-height: calc(100vh - 310px);
			display: flex;
			align-items: center;
			justify-content: center;

			@include breakPoint($breakPoint1){
				min-height: calc(100vh - 510px);
			}

			@include breakPoint($breakPoint2){
				min-height: calc(100vh - 420px);
			}

			@include breakPoint($breakPoint3){
				min-height: calc(100vh - 560px);
			}

			img{
				max-width: 100%;
				max-height: 100%;
				display: block;

				@include breakPoint($breakPoint1){
					max-height: calc(100vh - 600px);
				}

				@include breakPoint($breakPoint2){
					max-height: calc(100vh - 470px);
				}

				@include breakPoint($breakPoint3){
					max-height: calc(100vh - 570px);
				}
			}
		}

		&-info{
			ul{
				font-size: 0;
			}

			li{
				position: relative;
				padding-left: 15px;
				display: inline-block;
				vertical-align: middle;
				width: 50%;
				box-sizing: border-box;
				margin-bottom: 10px;
				padding-right: 10px;

				@include breakPoint($breakPoint1){
					padding-left: 20px;
				}

				@include breakPoint($breakPoint2){
					width: auto;
					padding-left: 15px;
				}


				@include breakPoint($breakPoint3){
					top: 4px;
					width: 25%;
					padding-left: 15px;
				}

				&:before{
					content: '';
					display: block;
					position: absolute;
					top: 4px;
					left: 0px;
					width: 10px;
					height: 10px;
					background: url("../img/svg/star.svg") no-repeat 50% 50%;
					background-size: contain;


					@include breakPoint($breakPoint1){
						width: 13px;
						height: 13px;
						top: 6px;
					}

					@include breakPoint($breakPoint2){
						width: 10px;
						height: 10px;
					}
				}
			}

			&-title{
				font-size: 16px;
				@extend %fontRobotoSlab;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					font-size: 21px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				@include breakPoint($breakPoint3){
					font-size: 16px;
				}

			}
			&-text{

				font-size: 12px;
				color: $colorGrey;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 10px;
				}

				@include breakPoint($breakPoint3){
					font-size: 12px;
				}
			}
		}
	}
}




.contact{

	position: relative;

	.inner{
		margin: 0;

		@include breakPoint($breakPoint2){
			padding-top: 110px;
		}

		@include breakPoint($breakPoint3){
			padding-top: 140px;
		}

	}


	&__title{
		display: none;
		@extend %fontTitle;
		font-size: 50px;
		color: #000;
		margin: 0 40px 20px;

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 20px;
			display: block;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 35px;
		}
	}

	&__control{
		position: absolute;
		z-index: 1;
		right: 20px;
		top: 100px;
		white-space: nowrap;

		@include breakPoint($breakPoint1){
			right: 30px;
			top: 145px;
		}

		@include breakPoint($breakPoint2){
			right: 56px;
			top: 46px;
		}

		@include breakPoint($breakPoint3){
			right: 90px;
			top: 72px;
		}

		&-arrow{

			fill: $colorGreyLight;
			display: inline-block;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}

			svg{
				width: 22px;
				height: 22px;
				position: relative;

				@include breakPoint($breakPoint1){
					width: 28px;
					height: 28px;
				}

				@include breakPoint($breakPoint3){
					width: 36px;
					height: 36px;
				}

			}

			&_prev{

				svg {
					transform: rotate(-90deg);
				}
			}

			&_next{
				margin-left: 60px;

				@include breakPoint($breakPoint1){
					margin-left: 80px;
				}

				@include breakPoint($breakPoint2){
					margin-left: 60px;
				}

				svg {
					transform: rotate(90deg);
				}
			}

			&.slick-disabled{
				opacity: 0.5;
				cursor: default;
				fill: $colorGreyLight;
			}
		}
	}

	&__item{
		position: relative;
		padding: 85px 0 0 0;
		min-height: 100vh;
		box-sizing: border-box;

		@include breakPoint($breakPoint1){
			padding-top: 100px;
		}

		@include breakPoint($breakPoint2){
			padding: 0;
			height: calc(100vh - 230px);
			min-height: 400px;
		}
	}

	&__content{
		position: relative;


		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_white.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_white.svg");
		}
	}

	&__map{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $colorGreyLight;

		&-btn{
			position: absolute;
			bottom: 75px;
			left: 20px;
			right: 20px;

			@include breakPoint($breakPoint1){
				left: 30px;
				right: 30px;
				bottom: 100px;
			}

			@include breakPoint($breakPoint2){
				display: none;
			}

			.btn{
				display: block;

				@include breakPoint($breakPoint1){
					display: inline-block;
				}
			}
		}
	}

	&__info{
		padding: 15px 20px;
		position: relative;
		background: #000;
		color: #fff;
		margin-bottom: 300px;

		@include breakPoint($breakPoint1){
			padding: 40px 30px;
		}

		@include breakPoint($breakPoint2){
			position: absolute;
			top: 0;
			right: 30px;
			bottom: 0;
			width: 480px;
			padding: 50px 40px;
			margin-bottom: 0;
		}


		@include breakPoint($breakPoint3){
			width: 730px;
			right: 40px;
			padding: 70px 50px 50px;
		}


		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;

			@include breakPoint($breakPoint2){
				display: none;
			}

		}
		&:before{
			bottom: calc(100% - 2px);
			background-image: url("../img/svg/adge_top_black.svg");
		}
		&:after{
			top: calc(100% - 2px);
			background-image: url("../img/svg/adge_bottom_black.svg");
		}


		&-title{
			font-size: 24px;
			@extend %fontRobotoSlab;
			font-weight: 600;
			margin-bottom: 20px;

			@include breakPoint($breakPoint1){
				font-size: 31px;
				margin-bottom: 25px;
			}

			@include breakPoint($breakPoint2){
				font-size: 20px;
			}
			@include breakPoint($breakPoint3){
				font-size: 30px;
				margin-bottom: 35px;
			}
		}

		&-item{
			padding-left: 30px;
			line-height: 1.4;
			margin-bottom: 10px;
			position: relative;

			@include breakPoint($breakPoint1){
				padding-left: 44px;
				margin-bottom: 30px;
			}

			@include breakPoint($breakPoint2){
				padding-left: 30px;
				margin-bottom: 25px;
			}

			&:last-child{
				margin-bottom: 0;
			}

			&-ico{
				position: absolute;
				fill: $colorOrange;
				width: 20px;
				height: 20px;
				top: -3px;
				left: 0;

				@include breakPoint($breakPoint1){
					width: 25px;
					height: 25px;
					top: -3px;
				}

				@include breakPoint($breakPoint2){
					width: 20px;
					height: 20px;
				}

				svg{
					width: 100%;
					height: 100%;
				}
			}

			&-title{
				color: $colorOrange;
				font-size: 12px;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					margin-bottom: 5px;
				}
				@include breakPoint($breakPoint3){
					font-size: 14px;
				}
			}
			&-text{
				font-family: 'Roboto Slab', serif;
				font-size: 14px;

				@include breakPoint($breakPoint1){
					font-size: 21px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}
				@include breakPoint($breakPoint3){
					font-size: 16px;
				}

				span{
					display: inline-block;
					padding: 3px 5px;
				}
			}
			&-metro{
				display: none;
				margin-top: 10px;

				li{
					font-size: 14px;
					position: relative;
					margin: 5px 0;

					&:before{
						content: '';
						width: 16px;
						display: inline-block;
						vertical-align: middle;
						margin-right: 5px;
						height: 16px;
						background: url("../img/svg/metro.svg") no-repeat 50% 50%;
						background-size: contain;
					}

					a{
						color: $colorOrange;
						display: inline-block;
						margin-left: 10px;
						text-decoration: underline;

						&:hover{
							text-decoration: none;
						}
					}
				}


				@include breakPoint($breakPoint2){
					display: block;
				}
				@include breakPoint($breakPoint3){
					font-size: 16px;
				}
			}
		}

		&-footer{

			@include breakPoint($breakPoint1){
				margin: 50px 0 20px;
			}

			@include breakPoint($breakPoint2){
				margin: 100px 0 0;
			}
		}

		&-cta{
			margin: 45px 0;

			@include breakPoint($breakPoint1){
				display: inline-block;
				vertical-align: middle;
				margin: 0 60px 0 0;
			}
			@include breakPoint($breakPoint2){
				margin-bottom: 20px;
				display: block;
			}

			.btn{
				display: block;
				font-size: 18px;
				padding: 12px 40px 14px;

				@include breakPoint($breakPoint1){
					font-size: 20px;
					padding: 17px 40px 19px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					padding: 10px 20px 11px;
					display: inline-block;
				}
			}
		}


		.js-scrollbar{

			@include breakPoint($breakPoint2){
				height: calc(100vh - 330px);
			}

			@include breakPoint($breakPoint3){
				height: calc(100vh - 435px);
			}
		}
	}
}


.map{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}


.fullpage-wrapper {
	width: 100%!important;
	transform: none!important;
}

.fp-section {
	width: 100%!important;
	position: absolute;
	left: 0;
	top: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 0;
	@include transition();
}

.fp-section.active {
	visibility: visible;
	opacity: 1;
	z-index: 1;
}
