$fontMain: 'Arial', sans-serif;
$fontSize: 16px;

$breakPoint1: 768px;
$breakPoint2: 1280px;
$breakPoint3: 1900px;


$formHeight: 42px;
$formBorderRadius: 4px;



$colorBlack: #000000;
$colorRed: #FF0000;
$colorOrange: #FF9900;
$colorOrangeLight: #FFB240;
$colorGrey: #000; //#616161;
$colorGreyLight: #A8A8A8;


$sprite: '';
