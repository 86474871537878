
html {
	height: 100%;
}

body {
	font-size: 16px;
	width: 100%;
	height: 100%;
	line-height: 1.2;

	@extend %fontProximaNova;
}

.wrapper {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	overflow: hidden;
}


.website{
	.show-menu &{
		filter: blur(5px);
	}
}

.btn{
	@extend %fontRobotoSlab;
	border-radius: 4px;
	color: #fff;
	font-size: 16px;
	padding: 19px 30px 22px;
	display: inline-block;
	text-align: center;
	background: $colorGreyLight;
	cursor: pointer;
	box-sizing: border-box;
	@include user-select();


	&_grad{
		box-shadow: 0 2px 8px rgba(#FF9900, 0.8);

		background: #ff9900;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmOTkwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjY2MDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top, #ff9900 0%, #ff6600 100%);
		background: -webkit-linear-gradient(top, #ff9900 0%,#ff6600 100%);
		background: linear-gradient(to bottom, #ff9900 0%,#ff6600 100%);
		//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9900', endColorstr='#ff6600',GradientType=0 );

	}

	&_white{
		background: #fff;
		color: #000;
		box-shadow: 0 2px $colorGreyLight;

		&:hover{
			color: $colorOrange;
		}
	}

	@include breakPoint($breakPoint1){
		font-size: 20px;
		padding: 17px 40px 19px;
	}

	@include breakPoint($breakPoint2){
		font-size: 12px;
		padding: 10px 20px 11px;
	}

	@include breakPoint($breakPoint3){
		font-size: 16px;
		padding: 14px 30px 17px;
	}

}

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 11;

	&:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		height: 75px;
		left: 0;
		right: 0;
		background: url("../img/svg/header_bg.svg") no-repeat 50% 100%;
		background-size: auto 100%;

		@include breakPoint($breakPoint1){
			height: 80px;
		}

		@include breakPoint($breakPoint2){
			top: auto;
			bottom: 100%;
			margin-bottom: -80px;
			min-height: 5.6vw;
		}

		@include breakPoint($breakPoint3){
			margin-bottom: -100px;
		}
	}

	&__content{
		padding: 16px 20px 0;
		background: #000;
		position: relative;

		@include breakPoint($breakPoint1){
			padding: 11px 30px 0;
		}

		@include breakPoint($breakPoint2){
			padding: 15px 40px 0;
		}
	}

	&__phone{
		position: absolute;
		width: 35px;
		height: 35px;
		top: 13px;
		right: 87px;
		fill: $colorOrange;

		@include breakPoint($breakPoint2){
			display: none;
		}

		svg{
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__part{
		position: absolute;
		color: #fff;
		@extend %fontTitle;
		font-size: 24px;
		top: 20px;
		opacity: 0;
		pointer-events: none;
		transition: opacity .3s ease;
		width: calc(100% - 143px);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		@include breakPoint($breakPoint1){
			font-size: 31px;
			top: 14px;
		}

		@include breakPoint($breakPoint2){
			display: none;
		}

		&.is-active{
			opacity: 1;

			& + .header__logo{

				@include breakPoint(0, $breakPoint2 - 1px ){
					opacity: 0;
				}
			}
		}


	}

	&__logo{
		width: 199px;
		height: 32px;
		opacity: 1;
		transition: opacity .3s ease;

		@include breakPoint($breakPoint1){
			width: 320px;
			height: 40px;
		}

		@include breakPoint($breakPoint2){
			width: 270px;
			height: 32px;
			margin-left: 65px;
			float: left;
		}

		@include breakPoint($breakPoint3){
			width: 360px;
			height: 46px;
			margin-left: 105px;
		}

		img{
			width: 100%;
			display: block;
		}
	}

	&__contact{

		display: none;
		color: #fff;
		fill:#fff;
		@extend %fontRobotoSlab;
		font-size: 14px;

		@include breakPoint($breakPoint2){
			display: block;
			float: right;
			white-space: nowrap;
		}

		@include breakPoint($breakPoint3){
			font-size: 16px;
		}

		&-phones{
			display: inline-block;
			vertical-align: middle;
			position: relative;

			&-ico{
				display: inline-block;
				width: 19px;
				height: 19px;
				vertical-align: middle;
				fill: $colorOrange;
				margin-right: 5px;

				@include breakPoint($breakPoint3){
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}

				svg{
					width: 100%;
					height: 100%;
				}
			}

			&-num{
				display: inline-block;
				vertical-align: middle;
				position: relative;
				cursor: pointer;

				@include breakPoint($breakPoint3){
					font-size: 18px;
				}

				&:after{
					content: '';
					position: absolute;
					display: block;
					border: 5px solid transparent;
					border-top-color: $colorOrange;
					top: 7px;
					right: -16px;
				}
			}
		}

		&-dropdown{
			position: absolute;
			display: none;
			pointer-events: none;
			background: #fff;
			border-radius: 4px;
			top: -15px;
			left: -15px;
			width: 330px;
			box-sizing: border-box;
			color: #000;
			white-space: normal;
			padding: 30px 20px;
			line-height: 1.5;
			opacity: 0;
			@include transition(all 0.3s ease);


			@include breakPoint($breakPoint2){
				display: block;
				width: 350px;
			}

			@include breakPoint($breakPoint3){
				width: 400px;
			}


			.is-open &{
				opacity: 1;
				pointer-events: all;
			}
		}

		&-email{
			display: inline-block;
			vertical-align: middle;
			margin-left: 40px;

			@include breakPoint($breakPoint3){
				margin-left: 60px;
			}
		}

		&-soc{
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;

			@include breakPoint($breakPoint3){
				margin-left: 40px;
			}

			&-item{
				display: inline-block;
				vertical-align: middle;
				height: 15px;
				margin-left: 20px;
				cursor: pointer;


				@include breakPoint($breakPoint3){
					height: 22px;
				}

				svg{
					height: 100%;
				}

				&:hover{
					fill: $colorOrange;
				}
			}
		}

		&-cta{
			display: inline-block;
			vertical-align: middle;
			margin-left: 40px;
		}
	}



	&__nav{

	}
}


.nav{

	position: fixed;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	transition: all 0.3s ease;
	box-sizing: border-box;
	background: #000;
	color: #fff;
	z-index: 15;

	@include breakPoint($breakPoint2){
		left: -1.8vw;
		width: 1080px;
		transform: translateX(-100%);
	}

	@include breakPoint($breakPoint2){

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: -1.7vw;
			width: 1.8vw;
			background: url(../img/svg/nav_edge.svg) no-repeat 50% 50%;
			background-size: 100% auto;
		}
	}

	.show-menu &{
		left: 0;

		@include breakPoint($breakPoint2){
			transform: translateX(0);
		}
	}

	&__handler{
		fill: $colorOrange;
		position: fixed;
		width: 74px;
		height: 74px;
		top: -1px;
		right: -1px;
		overflow: hidden;
		cursor: pointer;

		@include burgerButton ('.show-menu', 22px, 2px, 0);

		@include breakPoint($breakPoint2){
			left: 100%;
			position: absolute;
			width: 94px;

			.show-menu &{
				fill: #000;
			}
		}

		@include breakPoint($breakPoint3){
			width: 120px;
			height: 95px;

			@include burgerButton ('.show-menu', 30px, 3px, 0);
		}

		&:hover{
			fill: $colorOrangeLight;

			@include breakPoint($breakPoint2){

				.show-menu &{
					fill: #000;

					i{
						&:before,
						&:after{
							background: $colorOrange;
						}
					}
				}
			}
		}


		i{
			top: 45%;
			left: 56%;
			pointer-events: none;

			@include breakPoint($breakPoint2){
				left: 55%;
			}
		}

		svg{
			height: 100%;
			//width: auto;
			position: absolute;
			top: -5px;
			left: -13px;
			transition: fill 0.3s ease;

			@include breakPoint($breakPoint2){
				left: auto;
				right: -13px;
			}

			@include breakPoint($breakPoint3){
				right: 0;
			}
		}
	}

	&__wrap{

	}

	&__inner{
		height: 100%;
		overflow: auto;
	}

	&__menu{
		font-size: 24px;
		@extend %fontTitle;
		padding: 30px 20px;
		margin-bottom: 45px;
		box-sizing: border-box;

		@include breakPoint($breakPoint1){
			padding: 50px 30px;
			font-size: 31px;
			margin-bottom: 0;
		}

		@include breakPoint($breakPoint2){
			font-size: 40px;
			width: 560px;
			float: left;
			padding: 70px 0 20px 80px;
		}

		ul{
		}

			li{
				margin: 35px 0;

			@include breakPoint($breakPoint1){
				margin: 40px 0;
			}

			@include breakPoint($breakPoint2){
				margin: 33px 0;
			}


			&:first-child{
				margin-top: 0;
		}

			&:last-child{
				margin-bottom: 0;
			}
		}
		&-link{
			cursor: pointer;

			&.is-active{
				color: $colorOrange;
			}
		}
	}



	&__contact{
		padding: 40px 20px;

		@include breakPoint($breakPoint1){
			padding: 40px 30px;
		}

		@include breakPoint($breakPoint2){
			margin-left: 640px;
			width: 350px;
			padding: 90px 0 20px;
		}


		&-title{
			font-size: 24px;
			@extend %fontTitle;
			margin-bottom: 11px;

			@include breakPoint($breakPoint1){
				font-size: 31px;
				margin-bottom: 25px;
			}

			@include breakPoint($breakPoint2){
				font-size: 20px;
			}
		}

		&-item{
			padding-left: 30px;
			line-height: 1.4;
			margin-bottom: 20px;
			position: relative;

			@include breakPoint($breakPoint1){
				padding-left: 44px;
				margin-bottom: 30px;
			}

			@include breakPoint($breakPoint2){
				padding-left: 30px;
				margin-bottom: 25px;
			}

			&:last-child{
				margin-bottom: 0;
			}

			&-ico{
				position: absolute;
				fill: $colorOrange;
				width: 20px;
				height: 20px;
				top: -3px;
				left: 0;

				@include breakPoint($breakPoint1){
					width: 25px;
					height: 25px;
					top: -3px;
				}

				@include breakPoint($breakPoint2){
					width: 20px;
					height: 20px;
				}

				svg{
					width: 100%;
					height: 100%;
				}
			}

			&-title{
				color: $colorOrange;
				font-size: 12px;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					margin-bottom: 5px;
				}
			}
			&-text{
				font-family: 'Roboto Slab', serif;
				font-size: 14px;

				@include breakPoint($breakPoint1){
					font-size: 21px;
				}

				@include breakPoint($breakPoint2){
					font-size: 14px;
				}

				span{
					display: inline-block;
					padding: 3px 5px;
				}
			}
			&-metro{
				display: none;
				margin-top: 10px;

				li{
					font-size: 14px;
					position: relative;
					margin: 5px 0;

					&:before{
						content: '';
						width: 16px;
						display: inline-block;
						vertical-align: middle;
						margin-right: 5px;
						height: 16px;
						background: url("../img/svg/metro.svg") no-repeat 50% 50%;
						background-size: contain;
					}

					a{
						color: $colorOrange;
						display: inline-block;
						margin-left: 10px;
						text-decoration: underline;

						&:hover{
							text-decoration: none;
						}
					}
				}


				@include breakPoint($breakPoint2){
					display: block;
				}
			}
		}

		&-footer{

			@include breakPoint($breakPoint1){
				margin: 50px 0 20px;
			}

			@include breakPoint($breakPoint2){
				margin: 100px 0 0;
			}
		}

		&-cta{
			margin: 45px 0;

			@include breakPoint($breakPoint1){
				display: inline-block;
				vertical-align: middle;
				margin: 0 60px 0 0;
			}
			@include breakPoint($breakPoint2){
				margin-bottom: 20px;
				display: block;
			}

			.btn{
				display: block;
				font-size: 18px;
				padding: 12px 40px 14px;

				@include breakPoint($breakPoint1){
					font-size: 20px;
					padding: 17px 40px 19px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
					padding: 10px 20px 11px;
					display: inline-block;
				}
			}
		}

		&-soc{
			text-align: center;

			@include breakPoint($breakPoint1){
				display: inline-block;
				vertical-align: middle;
			}

			&-item{
				fill:#fff;
				height: 28px;
				display: inline-block;
				vertical-align: middle;
				margin: 0 25px ;

				@include breakPoint($breakPoint2){
					height: 16px;
					margin: 0 8px ;
				}


				&:first-child{
					margin-left: 0;
				}

				&:last-child{
					margin-right: 0;
				}

				svg{
					height: 100%;
					//width: auto;
				}


				&:hover{
					fill:$colorOrange;
				}
			}
		}
	}

}







.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10;

	&__bg{
		display: block;
		position: absolute;
		bottom: 0;
		height: 65px;
		left: 0;
		right: 0;

		@include breakPoint($breakPoint1){
			height: 80px;
		}

		@include breakPoint($breakPoint2){
			height: 50px;
		}

		@include breakPoint($breakPoint3){
			//height: 100px;
		}


		svg{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 100%;
			min-width: 101%;
			fill: #000;
			transition: fill 0.5s ease;

			@include breakPoint($breakPoint2){
				height: auto;
				min-width: 1370px;
				width: 101%;
				min-height: 5.8vw;
			}

			.is-light &{
				fill: #fff;
			}
		}
	}

	&__content{
		//margin: 0 20px;
		position: relative;
		height: 40px;

		@include breakPoint($breakPoint1){
			height: 48px;
			//margin: 0 30px;
		}

		@include breakPoint($breakPoint2){
			height: 30px;
			//margin: 0 40px;
		}
	}

	&__counter{
		font-family: 'Roboto Slab', serif;
		font-weight: 300;
		font-size: 12px;
		color: #fff;
		transition: color 0.5s ease;
		line-height: 0.7;

		@include breakPoint($breakPoint1){
			font-size: 16px;
		}

		@include breakPoint($breakPoint2){
			float: right;
			font-size: 12px;
		}

		.is-light &{
			color: #000;
		}

		&-current{
			font-weight: 400;
			font-size: 28px;
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;

			@include breakPoint($breakPoint1){
				font-size: 35px;
			}

			@include breakPoint($breakPoint2){
				font-size: 28px;
				margin-right: 2px;
			}
		}

		&-all{
			display: inline-block;
			vertical-align: top;

		}
	}



	&__control{
		top: 0;
		position: absolute;
		right: 0;
		white-space: nowrap;

		@include breakPoint($breakPoint2){
			display: none;
		}

		&-item{
			width: 25px;
			height: 25px;
			display: inline-block;
			vertical-align: middle;
			fill: $colorGreyLight;
			cursor: pointer;

			&:hover{
				fill: $colorOrange;
			}


			svg{
				width: 100%;
				height: 100%;
			}

			&_prev{

			}

			&_next{
				margin-left: 50px;

				svg{
					transform: rotate(180deg);
				}
			}

			&.is-hide{
				display: none;
			}
		}
	}


	&__message{
		display: none;
		color: #fff;
		font-size: 10px;
		float: left;
		margin-top: 6px;

		.is-light &{
			color: $colorGreyLight;
		}

		@include breakPoint($breakPoint2){
			display: block;
		}

		@include breakPoint($breakPoint3){
			display: block;
			font-size: 14px;
		}
	}
}



.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	width: 6px;
	background: $colorGreyLight;
	opacity: 1;
}
.mCSB_scrollTools .mCSB_draggerRail{
	opacity: 0;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
	opacity: 1;
	background: $colorOrange;
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
	opacity: 1;
	background: $colorOrange;
}
