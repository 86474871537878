@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,600&subset=cyrillic');
// font-family: 'Roboto Slab', serif;


@font-face {
	font-family: 'RUSBoycott';
	src: url('/fonts/RUSBoycott/RUSBoycott.eot');
	src: local('☺'),
	url('../fonts/RUSBoycott/RUSBoycott.woff') format('woff'),
	url('../fonts/RUSBoycott/RUSBoycott.ttf') format('truetype'),
	url('../fonts/RUSBoycott/RUSBoycott.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/proximanova/ProximaNova-Light.eot');
	src: local('☺'),
	url('../fonts/proximanova/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/proximanova/ProximaNova-Light.woff') format('woff'),
	url('../fonts/proximanova/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}


%fontTitle {
	font-family: 'RUSBoycott', sans-serif;
	font-weight: 400;
}

%fontRobotoSlab {
	font-family: 'Roboto Slab', sans-serif;
	font-weight: 400;
}

%fontProximaNova {
	font-family: 'Proxima Nova', sans-serif;
	font-weight: 300;
}