@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600&subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,600&subset=cyrillic");
@font-face { font-family: 'RUSBoycott'; src: url("/fonts/RUSBoycott/RUSBoycott.eot"); src: local("☺"), url("../fonts/RUSBoycott/RUSBoycott.woff") format("woff"), url("../fonts/RUSBoycott/RUSBoycott.ttf") format("truetype"), url("../fonts/RUSBoycott/RUSBoycott.svg") format("svg"); font-weight: normal; font-style: normal; }

@font-face { font-family: 'Proxima Nova'; src: url("../fonts/proximanova/ProximaNova-Light.eot"); src: local("☺"), url("../fonts/proximanova/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/proximanova/ProximaNova-Light.woff") format("woff"), url("../fonts/proximanova/ProximaNova-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }

.header__part, .nav__menu, .nav__contact-title, .welcome__title, .platform__title, .platform__item-info-title, .programm__title, .programm__tabs-num, .programm__tabs-title, .programm__item-picture-age, .programm__item-title, .license__title, .license__item-part, .command__title, .command__tabs-title, .command__item-picture-age, .command__item-title, .feedback__title, .feedback__item-info-name, .vehicle__title, .vehicle__item-title, .contact__title, .popup__title, .popup_detail .popup__course-title, .popup_detail .popup__coach-title { font-family: 'RUSBoycott', sans-serif; font-weight: 400; }

.form__input, .btn, .header__contact, .welcome__subtitle, .platform__item-info-address, .programm__item-course-title, .programm__item-course-name, .programm__item-price-name, .programm__item-price-pay, .license__item-title, .command__item-course-title, .command__item-course-name, .feedback__item-info-title, .feedback__item-body-title, .vehicle__item-info-title, .contact__info-title, .popup_detail .popup__course-price { font-family: 'Roboto Slab', sans-serif; font-weight: 400; }

body, .popup__form .form__field_submit .footnote, .popup_detail .popup__course-price-buy span { font-family: 'Proxima Nova', sans-serif; font-weight: 300; }

@media print { @page { margin: 1.5cm; }
  body { width: 100% !important; margin: 0 !important; padding: 0 !important; line-height: 1.45; font-family: Garamond, "Times New Roman", serif; font-size: 14pt; }
  * { background: none !important; color: black !important; box-shadow: none !important; text-shadow: none !important; }
  a { text-decoration: underline; }
  a[href]:after { content: " (" attr(href) ")"; }
  a[href="#"], a[href="javascript:"] { content: ""; }
  p { widows: 4; orphans: 3; }
  ul, table, figure { page-break-inside: avoid; }
  h1, h2, h3, h4, h5, h6 { page-break-after: avoid; } }

body { line-height: 1; }

* { margin: 0; padding: 0; border: 0; outline: 0; -moz-text-size-adjust: none; -ms-text-size-adjust: none; text-size-adjust: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); -webkit-tap-highlight-color: transparent; -webkit-touch-callout: none; }

*:active, *:focus { outline: none; }

ol, ul { list-style: none; }

a { color: inherit; text-decoration: none; }

table { border-collapse: collapse; border-spacing: 0; }

img { border: 0; -ms-interpolation-mode: bicubic; }

h1, h2, h3, h4, h5, h6 { font: inherit; margin: 0; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

select, textarea, button, input { font: inherit; color: inherit; overflow: visible; -webkit-appearance: none; -moz-appearance: none; appearance: none; background: transparent; }

select::-webkit-input-placeholder, textarea::-webkit-input-placeholder, button::-webkit-input-placeholder, input::-webkit-input-placeholder { color: currentColor; opacity: 0.5; text-overflow: ellipsis; -webkit-transition: opacity 0.25s ease; -khtml-transition: opacity 0.25s ease; -moz-transition: opacity 0.25s ease; -ms-transition: opacity 0.25s ease; -o-transition: opacity 0.25s ease; transition: opacity 0.25s ease; }

select::-moz-placeholder, textarea::-moz-placeholder, button::-moz-placeholder, input::-moz-placeholder { color: currentColor; opacity: 0.5; text-overflow: ellipsis; -webkit-transition: opacity 0.25s ease; -khtml-transition: opacity 0.25s ease; -moz-transition: opacity 0.25s ease; -ms-transition: opacity 0.25s ease; -o-transition: opacity 0.25s ease; transition: opacity 0.25s ease; }

select:-moz-placeholder, textarea:-moz-placeholder, button:-moz-placeholder, input:-moz-placeholder { color: currentColor; opacity: 0.5; text-overflow: ellipsis; -webkit-transition: opacity 0.25s ease; -khtml-transition: opacity 0.25s ease; -moz-transition: opacity 0.25s ease; -ms-transition: opacity 0.25s ease; -o-transition: opacity 0.25s ease; transition: opacity 0.25s ease; }

select:-ms-input-placeholder, textarea:-ms-input-placeholder, button:-ms-input-placeholder, input:-ms-input-placeholder { color: currentColor; opacity: 0.5; text-overflow: ellipsis; -webkit-transition: opacity 0.25s ease; -khtml-transition: opacity 0.25s ease; -moz-transition: opacity 0.25s ease; -ms-transition: opacity 0.25s ease; -o-transition: opacity 0.25s ease; transition: opacity 0.25s ease; }

select:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder, button:focus::-webkit-input-placeholder, input:focus::-webkit-input-placeholder { opacity: 0; }

select:focus::-moz-placeholder, textarea:focus::-moz-placeholder, button:focus::-moz-placeholder, input:focus::-moz-placeholder { opacity: 0; }

select:focus:-moz-placeholder, textarea:focus:-moz-placeholder, button:focus:-moz-placeholder, input:focus:-moz-placeholder { opacity: 0; }

select:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder, button:focus:-ms-input-placeholder, input:focus:-ms-input-placeholder { opacity: 0; }

select::-moz-focus-inner, textarea::-moz-focus-inner, button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

select::-ms-expand, textarea::-ms-expand, button::-ms-expand, input::-ms-expand { display: none; }

select::-ms-clear, textarea::-ms-clear, button::-ms-clear, input::-ms-clear { display: none; }

select::-ms-reveal, textarea::-ms-reveal, button::-ms-reveal, input::-ms-reveal { display: none; }

textarea { resize: none; overflow: auto; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

input[type="search"]::-webkit-inner-spin-button, input[type="search"]::-webkit-outer-spin-button { height: auto; }

input[type="search"]::-ms-clear { display: none; }

input[type="submit"] { cursor: pointer; }

label { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

[hidden] { display: none; }

/* mixins */
.clearfix:before { content: ""; display: table; }

.clearfix:after { content: ""; display: table; clear: both; }

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar { -ms-touch-action: pinch-zoom; touch-action: pinch-zoom; /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action { -ms-touch-action: auto; touch-action: auto; }

.mCustomScrollBox { /* contains plugin's markup */ position: relative; overflow: hidden; height: 100%; max-width: 100%; outline: none; direction: ltr; }

.mCSB_container { /* contains the original content */ overflow: hidden; width: auto; height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container { margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden { margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container { /* RTL direction/left-side scrollbar */ margin-right: 0; margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden { margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools { /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */ position: absolute; width: 16px; height: auto; left: auto; top: 0; right: 0; bottom: 0; }

.mCSB_outside + .mCSB_scrollTools { right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools { /* RTL direction/left-side scrollbar */ right: auto; left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools { left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer { /* contains the draggable element and dragger rail markup */ position: absolute; top: 0; left: 0; bottom: 0; right: 0; height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer { margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail { width: 2px; height: 100%; margin: 0 auto; -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger { /* the draggable element */ cursor: pointer; width: 100%; height: 30px; /* minimum dragger height */ z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { /* the dragger element */ position: relative; width: 4px; height: 100%; margin: 0 auto; -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px; text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar { width: 12px; /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail { width: 8px; /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_buttonDown { display: block; position: absolute; height: 20px; width: 100%; overflow: hidden; margin: 0 auto; cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown { bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container { margin-right: 0; margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container { min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden { margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal { width: auto; height: 16px; top: auto; right: 0; bottom: 0; left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal { bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer { margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail { width: 100%; height: 2px; margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger { width: 30px; /* minimum dragger width */ height: 100%; left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { width: 100%; height: 4px; margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar { height: 12px; /* auto-expanded scrollbar */ margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail { height: 8px; /* auto-expanded scrollbar */ margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight { display: block; position: absolute; width: 20px; height: 100%; overflow: hidden; margin: 0 auto; cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft { left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight { right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper { position: absolute; height: auto; width: auto; overflow: hidden; top: 0; left: 0; right: 0; bottom: 0; margin-right: 30px; margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container { padding-right: 30px; padding-bottom: 30px; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical { bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal { right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical { bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal { right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal { left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal { left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper { /* RTL direction/left-side scrollbar */ margin-right: 0; margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container { padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container { padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden { margin-right: 0; /* non-visible scrollbar */ margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden { margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight { -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out; -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out; -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out; transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail { -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/*
----------------------------------------
6.1 THEMES
----------------------------------------
*/
/* default theme ("light") */
.mCSB_scrollTools { opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools { opacity: 0; filter: "alpha(opacity=0)"; -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools { opacity: 1; filter: "alpha(opacity=100)"; -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.4); }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.75); }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #fff; background-color: white; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.9); filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight { background-image: url(mCSB_buttons.png); /* css sprites */ background-repeat: no-repeat; opacity: 0.4; filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp { background-position: 0 0; /* sprites locations light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px */ }

.mCSB_scrollTools .mCSB_buttonDown { background-position: 0 -20px; /* sprites locations light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px */ }

.mCSB_scrollTools .mCSB_buttonLeft { background-position: 0 -40px; /* sprites locations light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px */ }

.mCSB_scrollTools .mCSB_buttonRight { background-position: 0 -56px; /* sprites locations light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px */ }

.mCSB_scrollTools .mCSB_buttonUp:hover, .mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover { opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active, .mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active { opacity: 0.9; filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp { background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown { background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft { background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight { background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail { width: 4px; background-color: #fff; background-color: rgba(255, 255, 255, 0.1); -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 4px; background-color: #fff; background-color: rgba(255, 255, 255, 0.75); -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { width: 100%; height: 4px; margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp { background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown { background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft { background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight { background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.1); -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp { background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown { background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft { background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight { background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail { width: 4px; background-color: #fff; background-color: rgba(255, 255, 255, 0.1); -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 6px; background-color: #fff; background-color: rgba(255, 255, 255, 0.75); -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail { width: 100%; height: 4px; margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { width: 100%; height: 6px; margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp { background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown { background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft { background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight { background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.1); -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp { background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown { background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft { background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight { background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail { background-color: #fff; background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail { width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { width: 100%; height: 2px; margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp { background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown { background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft { background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight { background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail { background-color: #fff; background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger { height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 14px; margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger { width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { height: 14px; margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar { width: 16px; /* auto-expanded scrollbar */ height: 16px; margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail { width: 4px; /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar { height: 16px; /* auto-expanded scrollbar */ width: 16px; margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail { height: 4px; /* auto-expanded scrollbar */ margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp { background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown { background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft { background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight { background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp { background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown { background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft { background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight { background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail { width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail { background-color: transparent; background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg=="); background-repeat: repeat-y; opacity: 0.3; filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail { height: 4px; margin: 6px 0; background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp { background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown { background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft { background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight { background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp { background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown { background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft { background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight { background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-repeat: repeat-y; background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0))); background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { background-repeat: repeat-x; background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0))); background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger { height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger { width: 70px; }

.mCS-3d.mCSB_scrollTools, .mCS-3d-dark.mCSB_scrollTools { opacity: 1; filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail { width: 8px; background-color: #000; background-color: rgba(0, 0, 0, 0.2); box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail { width: 100%; height: 8px; margin: 4px 0; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { width: 100%; height: 8px; margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp { background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown { background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft { background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight { background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.1); box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail { box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp { background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown { background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft { background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight { background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools { opacity: 1; filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer { -webkit-border-radius: 7px; -moz-border-radius: 7px; border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical { right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical, .mCS-3d-thick-dark.mCSB_scrollTools_vertical { box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal { bottom: 1px; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4); width: 12px; margin: 2px; position: absolute; height: auto; top: 0; bottom: 0; left: 0; right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { height: 12px; width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer { background-color: #000; background-color: rgba(0, 0, 0, 0.05); box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail { background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp { background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown { background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft { background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight { background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools { box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal { box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer { background-color: #fff; background-color: rgba(0, 0, 0, 0.05); box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp { background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown { background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft { background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight { background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical { right: 0; margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal { bottom: 0; margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical { left: 0; right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger { height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger { width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.2); filter: "alpha(opacity=20)"; -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.5); filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.2); filter: "alpha(opacity=20)"; -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.5); filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail { width: 6px; background-color: #000; background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail { width: 100%; height: 6px; margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail { width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail { height: 12px; margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp { background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown { background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft { background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight { background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp { background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown { background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft { background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight { background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail { width: 12px; background-color: #000; background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 6px; margin: 3px 5px; position: absolute; height: auto; top: 0; bottom: 0; left: 0; right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar { height: 6px; margin: 5px 3px; position: absolute; width: auto; top: 0; bottom: 0; left: 0; right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail { width: 100%; height: 12px; margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp { background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown { background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft { background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight { background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp { background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown { background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft { background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight { background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: transparent; border-width: 1px; border-style: solid; border-color: #fff; border-color: rgba(255, 255, 255, 0.2); -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail { border-color: #000; border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail { background-color: #fff; background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail { background-color: #000; background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #000; background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background-color: #fff; background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

.placeholder { position: absolute; color: #000000; top: 0; right: 100%; left: 0; text-align: right; line-height: 42px; cursor: default; pointer-events: none; white-space: nowrap; -webkit-box-sizing: border-box; -khtml-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; -webkit-transition: all 0.3s ease; -khtml-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; padding: 0 0; }

.f-error .placeholder { color: #FF0000; }

input:focus ~ .placeholder, textarea:focus ~ .placeholder, .f-filled .placeholder { right: 0; font-size: 12px; }

.form { font-size: 0; }

.form__field { position: relative; margin-bottom: 30px; font-size: 16px; box-sizing: border-box; }

@media (min-width: 768px) { .form__field { font-size: 18px; margin-bottom: 40px; } }

@media (min-width: 1280px) { .form__field { font-size: 14px; } }

@media (min-width: 1900px) { .form__field { font-size: 16px; } }

.form__field:last-child { margin-bottom: 0; }

.form__field.f-error input[type="email"], .form__field.f-error input[type="password"], .form__field.f-error input[type="tel"], .form__field.f-error input[type="text"] { border-color: #FF0000 !important; border-bottom-style: solid; }

.form__message { display: none; top: 8px; font-size: 12px; position: relative; }

.f-message .form__message { display: block; }

.f-error .form__message { color: #FF0000; }

.form__label { position: relative; }

.form__input { position: relative; color: #000; }

.form__input input[type="email"], .form__input input[type="password"], .form__input input[type="tel"], .form__input input[type="text"] { -webkit-box-sizing: border-box; -khtml-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; width: 100%; display: block; color: #000000; line-height: 40px; height: 42px; border-bottom: 2px dashed #A8A8A8; -webkit-border-radius: 0; -khtml-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; border-radius: 0; background: #fff; -webkit-transition: border 0.3s ease; -khtml-transition: border 0.3s ease; -moz-transition: border 0.3s ease; -ms-transition: border 0.3s ease; -o-transition: border 0.3s ease; transition: border 0.3s ease; padding: 0 0; }

@media (min-width: 768px) { .form__input input[type="email"], .form__input input[type="password"], .form__input input[type="tel"], .form__input input[type="text"] { line-height: 58px; height: 60px; } }

@media (min-width: 1280px) { .form__input input[type="email"], .form__input input[type="password"], .form__input input[type="tel"], .form__input input[type="text"] { line-height: 48px; height: 50px; } }

.form__input input[type="email"] .f-filled, .form__input input[type="password"] .f-filled, .form__input input[type="tel"] .f-filled, .form__input input[type="text"] .f-filled { border-bottom-style: solid; border-bottom-color: #A8A8A8; }

.form__input input[type="email"]:focus, .form__input input[type="password"]:focus, .form__input input[type="tel"]:focus, .form__input input[type="text"]:focus { border-bottom-style: solid; border-bottom-color: #000; }

.f-error .form__input input[type="email"], .f-error .form__input input[type="password"], .f-error .form__input input[type="tel"], .f-error .form__input input[type="text"] { border-color: #FF0000; background: url("../img/svg/fail.svg") no-repeat 100% 50%; background-size: 25px; }

.form__input input[type="submit"] { -webkit-box-sizing: border-box; -khtml-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; color: #fff; cursor: pointer; width: 100%; display: block; }

.form__input input[type="submit"]:not(.btn) { line-height: 42px; -webkit-border-radius: 0; -khtml-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; border-radius: 0; }

.form__input input[type="checkbox"] { opacity: 0; position: absolute; }

.form__input input[type="checkbox"]:checked ~ .form__label:before { background-color: green; }

.form__input input[type="checkbox"] ~ .form__label { padding: 7px 0 7px 42px; }

.form__input input[type="checkbox"] ~ .form__label:before { content: ''; width: 28px; height: 28px; display: block; position: absolute; top: 0; left: 0; -webkit-border-radius: 0; -khtml-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; border-radius: 0; background: #fff; border: 1px solid #000; }

.f-error .form__input input[type="checkbox"] ~ .form__label:before { border-color: #FF0000; border-bottom-style: solid; }

.form__input input[type="radio"] { opacity: 0; position: absolute; }

.form__input input[type="radio"]:checked ~ .form__label:before { background-color: green; }

.form__input input[type="radio"] ~ .form__label { padding: 7px 0 7px 42px; }

.form__input input[type="radio"] ~ .form__label:before { content: ''; width: 28px; height: 28px; display: block; position: absolute; top: 0; left: 0; -webkit-border-radius: 100px; -khtml-border-radius: 100px; -moz-border-radius: 100px; -ms-border-radius: 100px; -o-border-radius: 100px; border-radius: 100px; background: #fff; border: 1px solid #000; }

.f-error .form__input input[type="radio"] ~ .form__label:before { border-color: #FF0000; }

html { height: 100%; }

body { font-size: 16px; width: 100%; height: 100%; line-height: 1.2; }

.wrapper { min-height: 100%; height: auto !important; height: 100%; overflow: hidden; }

.show-menu .website { filter: blur(5px); }

.btn { border-radius: 4px; color: #fff; font-size: 16px; padding: 19px 30px 22px; display: inline-block; text-align: center; background: #A8A8A8; cursor: pointer; box-sizing: border-box; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none; user-select: none; }

.btn_grad { box-shadow: 0 2px 8px rgba(255, 153, 0, 0.8); background: #ff9900; background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmOTkwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjY2MDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+); background: -moz-linear-gradient(top, #ff9900 0%, #ff6600 100%); background: -webkit-linear-gradient(top, #ff9900 0%, #ff6600 100%); background: linear-gradient(to bottom, #ff9900 0%, #ff6600 100%); }

.btn_white { background: #fff; color: #000; box-shadow: 0 2px #A8A8A8; }

.btn_white:hover { color: #FF9900; }

@media (min-width: 768px) { .btn { font-size: 20px; padding: 17px 40px 19px; } }

@media (min-width: 1280px) { .btn { font-size: 12px; padding: 10px 20px 11px; } }

@media (min-width: 1900px) { .btn { font-size: 16px; padding: 14px 30px 17px; } }

.header { position: fixed; top: 0; left: 0; width: 100%; z-index: 11; }

.header:before { content: ''; display: block; position: absolute; top: 0; height: 75px; left: 0; right: 0; background: url("../img/svg/header_bg.svg") no-repeat 50% 100%; background-size: auto 100%; }

@media (min-width: 768px) { .header:before { height: 80px; } }

@media (min-width: 1280px) { .header:before { top: auto; bottom: 100%; margin-bottom: -80px; min-height: 5.6vw; } }

@media (min-width: 1900px) { .header:before { margin-bottom: -100px; } }

.header__content { padding: 16px 20px 0; background: #000; position: relative; }

@media (min-width: 768px) { .header__content { padding: 11px 30px 0; } }

@media (min-width: 1280px) { .header__content { padding: 15px 40px 0; } }

.header__phone { position: absolute; width: 35px; height: 35px; top: 13px; right: 87px; fill: #FF9900; }

@media (min-width: 1280px) { .header__phone { display: none; } }

.header__phone svg { display: block; width: 100%; height: 100%; }

.header__part { position: absolute; color: #fff; font-size: 24px; top: 20px; opacity: 0; pointer-events: none; transition: opacity .3s ease; width: calc(100% - 143px); white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }

@media (min-width: 768px) { .header__part { font-size: 31px; top: 14px; } }

@media (min-width: 1280px) { .header__part { display: none; } }

.header__part.is-active { opacity: 1; }

@media (min-width: 0) and (max-width: 1279px) { .header__part.is-active + .header__logo { opacity: 0; } }

.header__logo { width: 199px; height: 32px; opacity: 1; transition: opacity .3s ease; }

@media (min-width: 768px) { .header__logo { width: 320px; height: 40px; } }

@media (min-width: 1280px) { .header__logo { width: 270px; height: 32px; margin-left: 65px; float: left; } }

@media (min-width: 1900px) { .header__logo { width: 360px; height: 46px; margin-left: 105px; } }

.header__logo img { width: 100%; display: block; }

.header__contact { display: none; color: #fff; fill: #fff; font-size: 14px; }

@media (min-width: 1280px) { .header__contact { display: block; float: right; white-space: nowrap; } }

@media (min-width: 1900px) { .header__contact { font-size: 16px; } }

.header__contact-phones { display: inline-block; vertical-align: middle; position: relative; }

.header__contact-phones-ico { display: inline-block; width: 19px; height: 19px; vertical-align: middle; fill: #FF9900; margin-right: 5px; }

@media (min-width: 1900px) { .header__contact-phones-ico { width: 24px; height: 24px; margin-right: 8px; } }

.header__contact-phones-ico svg { width: 100%; height: 100%; }

.header__contact-phones-num { display: inline-block; vertical-align: middle; position: relative; cursor: pointer; }

@media (min-width: 1900px) { .header__contact-phones-num { font-size: 18px; } }

.header__contact-phones-num:after { content: ''; position: absolute; display: block; border: 5px solid transparent; border-top-color: #FF9900; top: 7px; right: -16px; }

.header__contact-dropdown { position: absolute; display: none; pointer-events: none; background: #fff; border-radius: 4px; top: -15px; left: -15px; width: 330px; box-sizing: border-box; color: #000; white-space: normal; padding: 30px 20px; line-height: 1.5; opacity: 0; -webkit-transition: all 0.3s ease; -khtml-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; }

@media (min-width: 1280px) { .header__contact-dropdown { display: block; width: 350px; } }

@media (min-width: 1900px) { .header__contact-dropdown { width: 400px; } }

.is-open .header__contact-dropdown { opacity: 1; pointer-events: all; }

.header__contact-email { display: inline-block; vertical-align: middle; margin-left: 40px; }

@media (min-width: 1900px) { .header__contact-email { margin-left: 60px; } }

.header__contact-soc { display: inline-block; vertical-align: middle; margin-left: 20px; }

@media (min-width: 1900px) { .header__contact-soc { margin-left: 40px; } }

.header__contact-soc-item { display: inline-block; vertical-align: middle; height: 15px; margin-left: 20px; cursor: pointer; }

@media (min-width: 1900px) { .header__contact-soc-item { height: 22px; } }

.header__contact-soc-item svg { height: 100%; }

.header__contact-soc-item:hover { fill: #FF9900; }

.header__contact-cta { display: inline-block; vertical-align: middle; margin-left: 40px; }

.nav { position: fixed; top: 0; left: 100%; width: 100%; height: 100%; transition: all 0.3s ease; box-sizing: border-box; background: #000; color: #fff; z-index: 15; }

@media (min-width: 1280px) { .nav { left: -1.8vw; width: 1080px; transform: translateX(-100%); } }

@media (min-width: 1280px) { .nav:before { content: ''; display: block; position: absolute; top: 0; bottom: 0; right: -1.7vw; width: 1.8vw; background: url(../img/svg/nav_edge.svg) no-repeat 50% 50%; background-size: 100% auto; } }

.show-menu .nav { left: 0; }

@media (min-width: 1280px) { .show-menu .nav { transform: translateX(0); } }

.nav__handler { fill: #FF9900; position: fixed; width: 74px; height: 74px; top: -1px; right: -1px; overflow: hidden; cursor: pointer; cursor: pointer; }

.nav__handler i { display: block; width: 22px; height: 2px; background: #fff; position: absolute; top: 50%; left: 50%; margin-top: -1px; margin-left: -11px; -webkit-transition: all 0.3s ease; -khtml-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; border-radius: 0; }

.show-menu .nav__handler i { width: 2px; margin-left: -1px; }

.nav__handler i:before, .nav__handler i:after { content: ''; display: block; width: 22px; height: 2px; background: #fff; position: absolute; left: 50%; top: 50%; margin-top: -1px; margin-left: -11px; -webkit-transition: all 0.3s ease; -khtml-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; border-radius: 0; }

.nav__handler i:before { top: -6.33333px; }

.show-menu .nav__handler i:before { top: 50%; -webkit-transform: rotate(45deg); -khtml-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); }

.nav__handler i:after { top: 8.33333px; }

.show-menu .nav__handler i:after { top: 50%; -webkit-transform: rotate(135deg); -khtml-transform: rotate(135deg); -moz-transform: rotate(135deg); -ms-transform: rotate(135deg); -o-transform: rotate(135deg); transform: rotate(135deg); }

@media (min-width: 1280px) { .nav__handler { left: 100%; position: absolute; width: 94px; }
  .show-menu .nav__handler { fill: #000; } }

@media (min-width: 1900px) { .nav__handler { width: 120px; height: 95px; cursor: pointer; }
  .nav__handler i { display: block; width: 30px; height: 3px; background: #fff; position: absolute; top: 50%; left: 50%; margin-top: -1.5px; margin-left: -15px; -webkit-transition: all 0.3s ease; -khtml-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; border-radius: 0; }
  .show-menu .nav__handler i { width: 3px; margin-left: -1.5px; }
  .nav__handler i:before, .nav__handler i:after { content: ''; display: block; width: 30px; height: 3px; background: #fff; position: absolute; left: 50%; top: 50%; margin-top: -1.5px; margin-left: -15px; -webkit-transition: all 0.3s ease; -khtml-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; border-radius: 0; }
  .nav__handler i:before { top: -8.5px; }
  .show-menu .nav__handler i:before { top: 50%; -webkit-transform: rotate(45deg); -khtml-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); }
  .nav__handler i:after { top: 11.5px; }
  .show-menu .nav__handler i:after { top: 50%; -webkit-transform: rotate(135deg); -khtml-transform: rotate(135deg); -moz-transform: rotate(135deg); -ms-transform: rotate(135deg); -o-transform: rotate(135deg); transform: rotate(135deg); } }

.nav__handler:hover { fill: #FFB240; }

@media (min-width: 1280px) { .show-menu .nav__handler:hover { fill: #000; }
  .show-menu .nav__handler:hover i:before, .show-menu .nav__handler:hover i:after { background: #FF9900; } }

.nav__handler i { top: 45%; left: 56%; pointer-events: none; }

@media (min-width: 1280px) { .nav__handler i { left: 55%; } }

.nav__handler svg { height: 100%; position: absolute; top: -5px; left: -13px; transition: fill 0.3s ease; }

@media (min-width: 1280px) { .nav__handler svg { left: auto; right: -13px; } }

@media (min-width: 1900px) { .nav__handler svg { right: 0; } }

.nav__inner { height: 100%; overflow: auto; }

.nav__menu { font-size: 24px; padding: 30px 20px; margin-bottom: 45px; box-sizing: border-box; }

@media (min-width: 768px) { .nav__menu { padding: 50px 30px; font-size: 31px; margin-bottom: 0; } }

@media (min-width: 1280px) { .nav__menu { font-size: 40px; width: 560px; float: left; padding: 70px 0 20px 80px; } }

.nav__menu li { margin: 35px 0; }

@media (min-width: 768px) { .nav__menu li { margin: 40px 0; } }

@media (min-width: 1280px) { .nav__menu li { margin: 33px 0; } }

.nav__menu li:first-child { margin-top: 0; }

.nav__menu li:last-child { margin-bottom: 0; }

.nav__menu-link { cursor: pointer; }

.nav__menu-link.is-active { color: #FF9900; }

.nav__contact { padding: 40px 20px; }

@media (min-width: 768px) { .nav__contact { padding: 40px 30px; } }

@media (min-width: 1280px) { .nav__contact { margin-left: 640px; width: 350px; padding: 90px 0 20px; } }

.nav__contact-title { font-size: 24px; margin-bottom: 11px; }

@media (min-width: 768px) { .nav__contact-title { font-size: 31px; margin-bottom: 25px; } }

@media (min-width: 1280px) { .nav__contact-title { font-size: 20px; } }

.nav__contact-item { padding-left: 30px; line-height: 1.4; margin-bottom: 20px; position: relative; }

@media (min-width: 768px) { .nav__contact-item { padding-left: 44px; margin-bottom: 30px; } }

@media (min-width: 1280px) { .nav__contact-item { padding-left: 30px; margin-bottom: 25px; } }

.nav__contact-item:last-child { margin-bottom: 0; }

.nav__contact-item-ico { position: absolute; fill: #FF9900; width: 20px; height: 20px; top: -3px; left: 0; }

@media (min-width: 768px) { .nav__contact-item-ico { width: 25px; height: 25px; top: -3px; } }

@media (min-width: 1280px) { .nav__contact-item-ico { width: 20px; height: 20px; } }

.nav__contact-item-ico svg { width: 100%; height: 100%; }

.nav__contact-item-title { color: #FF9900; font-size: 12px; }

@media (min-width: 768px) { .nav__contact-item-title { font-size: 16px; } }

@media (min-width: 1280px) { .nav__contact-item-title { font-size: 12px; margin-bottom: 5px; } }

.nav__contact-item-text { font-family: 'Roboto Slab', serif; font-size: 14px; }

@media (min-width: 768px) { .nav__contact-item-text { font-size: 21px; } }

@media (min-width: 1280px) { .nav__contact-item-text { font-size: 14px; } }

.nav__contact-item-text span { display: inline-block; padding: 3px 5px; }

.nav__contact-item-metro { display: none; margin-top: 10px; }

.nav__contact-item-metro li { font-size: 14px; position: relative; margin: 5px 0; }

.nav__contact-item-metro li:before { content: ''; width: 16px; display: inline-block; vertical-align: middle; margin-right: 5px; height: 16px; background: url("../img/svg/metro.svg") no-repeat 50% 50%; background-size: contain; }

.nav__contact-item-metro li a { color: #FF9900; display: inline-block; margin-left: 10px; text-decoration: underline; }

.nav__contact-item-metro li a:hover { text-decoration: none; }

@media (min-width: 1280px) { .nav__contact-item-metro { display: block; } }

@media (min-width: 768px) { .nav__contact-footer { margin: 50px 0 20px; } }

@media (min-width: 1280px) { .nav__contact-footer { margin: 100px 0 0; } }

.nav__contact-cta { margin: 45px 0; }

@media (min-width: 768px) { .nav__contact-cta { display: inline-block; vertical-align: middle; margin: 0 60px 0 0; } }

@media (min-width: 1280px) { .nav__contact-cta { margin-bottom: 20px; display: block; } }

.nav__contact-cta .btn { display: block; font-size: 18px; padding: 12px 40px 14px; }

@media (min-width: 768px) { .nav__contact-cta .btn { font-size: 20px; padding: 17px 40px 19px; } }

@media (min-width: 1280px) { .nav__contact-cta .btn { font-size: 12px; padding: 10px 20px 11px; display: inline-block; } }

.nav__contact-soc { text-align: center; }

@media (min-width: 768px) { .nav__contact-soc { display: inline-block; vertical-align: middle; } }

.nav__contact-soc-item { fill: #fff; height: 28px; display: inline-block; vertical-align: middle; margin: 0 25px; }

@media (min-width: 1280px) { .nav__contact-soc-item { height: 16px; margin: 0 8px; } }

.nav__contact-soc-item:first-child { margin-left: 0; }

.nav__contact-soc-item:last-child { margin-right: 0; }

.nav__contact-soc-item svg { height: 100%; }

.nav__contact-soc-item:hover { fill: #FF9900; }

.footer { position: fixed; bottom: 0; left: 0; width: 100%; z-index: 10; }

.footer__bg { display: block; position: absolute; bottom: 0; height: 65px; left: 0; right: 0; }

@media (min-width: 768px) { .footer__bg { height: 80px; } }

@media (min-width: 1280px) { .footer__bg { height: 50px; } }

.footer__bg svg { position: absolute; top: 0; left: 50%; transform: translateX(-50%); height: 100%; min-width: 101%; fill: #000; transition: fill 0.5s ease; }

@media (min-width: 1280px) { .footer__bg svg { height: auto; min-width: 1370px; width: 101%; min-height: 5.8vw; } }

.is-light .footer__bg svg { fill: #fff; }

.footer__content { position: relative; height: 40px; }

@media (min-width: 768px) { .footer__content { height: 48px; } }

@media (min-width: 1280px) { .footer__content { height: 30px; } }

.footer__counter { font-family: 'Roboto Slab', serif; font-weight: 300; font-size: 12px; color: #fff; transition: color 0.5s ease; line-height: 0.7; }

@media (min-width: 768px) { .footer__counter { font-size: 16px; } }

@media (min-width: 1280px) { .footer__counter { float: right; font-size: 12px; } }

.is-light .footer__counter { color: #000; }

.footer__counter-current { font-weight: 400; font-size: 28px; display: inline-block; vertical-align: top; margin-right: 10px; }

@media (min-width: 768px) { .footer__counter-current { font-size: 35px; } }

@media (min-width: 1280px) { .footer__counter-current { font-size: 28px; margin-right: 2px; } }

.footer__counter-all { display: inline-block; vertical-align: top; }

.footer__control { top: 0; position: absolute; right: 0; white-space: nowrap; }

@media (min-width: 1280px) { .footer__control { display: none; } }

.footer__control-item { width: 25px; height: 25px; display: inline-block; vertical-align: middle; fill: #A8A8A8; cursor: pointer; }

.footer__control-item:hover { fill: #FF9900; }

.footer__control-item svg { width: 100%; height: 100%; }

.footer__control-item_next { margin-left: 50px; }

.footer__control-item_next svg { transform: rotate(180deg); }

.footer__control-item.is-hide { display: none; }

.footer__message { display: none; color: #fff; font-size: 10px; float: left; margin-top: 6px; }

.is-light .footer__message { color: #A8A8A8; }

@media (min-width: 1280px) { .footer__message { display: block; } }

@media (min-width: 1900px) { .footer__message { display: block; font-size: 14px; } }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width: 6px; background: #A8A8A8; opacity: 1; }

.mCSB_scrollTools .mCSB_draggerRail { opacity: 0; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { opacity: 1; background: #FF9900; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { opacity: 1; background: #FF9900; }

section { min-height: 100vh; }

section { min-height: 100vh; }

.inner { margin: 0 20px; }

@media (min-width: 768px) { .inner { margin: 0 30px; } }

@media (min-width: 1280px) { .inner { margin: 0 40px; } }

.welcome { background: url("../img/pic/welcome_bg.jpg") no-repeat 50% 50%; background-size: cover; position: relative; }

.welcome .inner { padding: 110px 0 150px; }

@media (min-width: 768px) { .welcome .inner { padding: 150px 0 200px; } }

@media (min-width: 1280px) { .welcome .inner { padding: 140px 0 240px; } }

@media (min-width: 1900px) { .welcome .inner { padding: 190px 0 300px; } }

.welcome:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: url(../img/svg/adge_top_white.svg) repeat-x 50% 50%; background-size: auto 100%; z-index: 1; bottom: -2px; }

.welcome__subtitle { font-size: 21px; color: #FF9900; position: relative; margin-bottom: 5px; }

@media (min-width: 768px) { .welcome__subtitle { font-size: 27px; margin-bottom: 10px; } }

@media (min-width: 1280px) { .welcome__subtitle { font-size: 21px; margin-bottom: 20px; } }

@media (min-width: 1900px) { .welcome__subtitle { font-size: 30px; margin-bottom: 20px; } }

.welcome__title { font-size: 60px; color: #fff; position: relative; width: 290px; }

@media (min-width: 768px) { .welcome__title { font-size: 78px; width: 400px; } }

@media (min-width: 1280px) { .welcome__title { font-size: 78px; width: 780px; } }

@media (min-width: 1900px) { .welcome__title { font-size: 100px; width: 1000px; } }

.welcome__btn { position: absolute; bottom: 80px; left: 20px; right: 20px; }

@media (min-width: 768px) { .welcome__btn { bottom: 115px; left: 30px; right: 30px; } }

@media (min-width: 1280px) { .welcome__btn { display: none; } }

.welcome__btn .btn { font-size: 18px; display: block; padding: 14px 20px 15px; }

@media (min-width: 768px) { .welcome__btn .btn { font-size: 20px; display: inline-block; padding: 17px 40px 19px; } }

.welcome__footnote { display: none; width: 442px; height: 117px; position: absolute; background: url("../img/svg/welcome_footnote.svg") no-repeat 50% 50%; background-size: 100% 100%; box-sizing: border-box; padding: 38px 50px 0 48px; color: #FF9900; font-size: 16px; left: 40px; bottom: 113px; font-weight: 600; }

.welcome__footnote:before { content: ''; display: block; position: absolute; top: 37px; left: 34px; width: 10px; height: 10px; background: url("../img/svg/star.svg") no-repeat 50% 50%; background-size: contain; }

@media (min-width: 1900px) { .welcome__footnote:before { top: 54px; left: 38px; width: 12px; height: 12px; } }

@media (min-width: 1280px) { .welcome__footnote { display: block; } }

@media (min-width: 1900px) { .welcome__footnote { font-size: 24px; width: 634px; height: 168px; padding: 53px 60px 0 58px; } }

.platform { position: relative; }

.platform .inner { margin: 0; }

@media (min-width: 1280px) { .platform .inner { padding-top: 110px; } }

.platform__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .platform__title { font-size: 50px; margin-bottom: 20px; display: block; } }

@media (min-width: 1900px) { .platform__title { font-size: 70px; margin-bottom: 60px; } }

.platform__control { position: absolute; z-index: 1; right: 20px; top: 100px; white-space: nowrap; }

@media (min-width: 768px) { .platform__control { right: 30px; top: 110px; } }

@media (min-width: 1280px) { .platform__control { display: none; } }

.platform__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.platform__control-arrow:hover { fill: #FF9900; }

.platform__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .platform__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .platform__control-arrow svg { width: 36px; height: 36px; } }

.platform__control-arrow_prev svg { transform: rotate(-90deg); }

.platform__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .platform__control-arrow_next { margin-left: 80px; } }

.platform__control-arrow_next svg { transform: rotate(90deg); }

.platform__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.platform__content { position: relative; font-size: 0; }

.platform__content:before, .platform__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.platform__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.platform__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.platform__item { position: relative; min-height: 100vh; box-sizing: border-box; padding: 85px 0 300px; }

@media (min-width: 1280px) { .platform__item { width: 50%; display: inline-block; vertical-align: middle; min-height: calc(100vh - 190px); } }

@media (min-width: 1900px) { .platform__item { padding-bottom: 400px; min-height: calc(100vh - 253px); } }

.platform__item-map { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #A8A8A8 no-repeat 50% 50%; background-size: cover; overflow: hidden; }

@media (min-width: 1280px) { .platform__item:first-child .platform__item-map:before { content: ''; position: absolute; display: block; width: 2px; height: 100%; top: -30px; right: -1px; background: #FF9900; z-index: 2; } }

.platform__item-info { background: #000; padding: 10px 20px; position: relative; }

@media (min-width: 768px) { .platform__item-info { padding: 20px 30px; } }

@media (min-width: 1280px) { .platform__item-info { position: absolute; left: 13px; background: url("../img/pic/text_bg_black.png") no-repeat 50% 50%; background-size: 100% 100%; width: 465px; height: 137px; box-sizing: border-box; padding: 20px 30px 0 25px; top: 30px; } }

@media (min-width: 1900px) { .platform__item-info { width: 700px; height: 206px; padding: 35px 30px 0 40px; left: 20px; } }

.platform__item-info:before, .platform__item-info:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; }

@media (min-width: 1280px) { .platform__item-info:before, .platform__item-info:after { display: none; } }

.platform__item-info:before { bottom: calc(100% - 2px); background-image: url("../img/svg/adge_top_black.svg"); }

.platform__item-info:after { top: calc(100% - 2px); background-image: url("../img/svg/adge_bottom_black.svg"); }

.platform__item-info-title { font-size: 24px; color: #FF9900; margin-right: 140px; line-height: 1.3; margin-bottom: 5px; }

@media (min-width: 768px) { .platform__item-info-title { font-size: 31px; margin-bottom: 10px; } }

@media (min-width: 1280px) { .platform__item-info-title { font-size: 28px; margin-right: 0; margin-bottom: 3px; } }

@media (min-width: 1900px) { .platform__item-info-title { font-size: 40px; } }

.platform__item-info-address { font-size: 16px; color: #fff; margin-bottom: 10px; }

@media (min-width: 768px) { .platform__item-info-address { font-size: 21px; } }

@media (min-width: 1280px) { .platform__item-info-address { font-size: 12px; margin-bottom: 5px; } }

@media (min-width: 1900px) { .platform__item-info-address { font-size: 16px; margin-bottom: 10px; } }

.platform__item-info-text { font-size: 12px; color: #A8A8A8; margin-top: 7px; }

@media (min-width: 768px) { .platform__item-info-text { font-size: 16px; } }

@media (min-width: 1280px) { .platform__item-info-text { font-size: 12px; margin-top: 2px; } }

@media (min-width: 1900px) { .platform__item-info-text { font-size: 14px; margin-top: 5px; } }

.platform__item-control { position: absolute; bottom: 70px; left: 20px; right: 20px; padding-bottom: 5px; display: inline-block; font-size: 0; }

@media (min-width: 768px) { .platform__item-control { bottom: 90px; left: 30px; right: 30px; white-space: nowrap; } }

@media (min-width: 1280px) { .platform__item-control { left: 40px; right: 40px; } }

@media (min-width: 1900px) { .platform__item-control { bottom: 130px; } }

.platform__item-control .btn_grad { width: 100%; padding: 15px 10px 16px; vertical-align: top; margin-right: 9px; font-size: 16px; margin-bottom: 10px; }

@media (min-width: 1900px) { .platform__item-control .btn_grad { margin-right: 20px; } }

.platform__item-control .btn_white { width: 100%; padding: 15px 10px 16px; white-space: normal; vertical-align: top; font-size: 16px; }

@media (min-width: 768px) { .platform__item-control .btn { font-size: 20px; padding: 18px 20px 18px; width: auto; margin-bottom: 0; } }

@media (min-width: 1280px) { .platform__item-control .btn { font-size: 12px; padding: 11px 20px 10px; width: auto; } }

@media (min-width: 1900px) { .platform__item-control .btn { font-size: 16px; padding: 16px 30px 15px; } }

.programm { position: relative; }

.programm .inner { margin: 0; }

@media (min-width: 1280px) { .programm .inner { padding-top: 110px; } }

@media (min-width: 1900px) { .programm .inner { padding-top: 135px; } }

.programm__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .programm__title { font-size: 50px; margin-bottom: 20px; display: block; } }

@media (min-width: 1900px) { .programm__title { font-size: 70px; margin-bottom: 60px; } }

.programm__content { position: relative; font-size: 0; background: #F1F1F1; }

.programm__content:before, .programm__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.programm__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.programm__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.programm__control { position: absolute; z-index: 1; right: 20px; top: 90px; white-space: nowrap; }

@media (min-width: 768px) { .programm__control { right: 30px; top: 110px; } }

@media (min-width: 1280px) { .programm__control { display: none; } }

.programm__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.programm__control-arrow:hover { fill: #FF9900; }

.programm__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .programm__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .programm__control-arrow svg { width: 36px; height: 36px; } }

.programm__control-arrow_prev svg { transform: rotate(-90deg); }

.programm__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .programm__control-arrow_next { margin-left: 80px; } }

.programm__control-arrow_next svg { transform: rotate(90deg); }

.programm__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.programm__tabs { display: none; overflow: hidden; }

.programm__tabs .mCSB_container { margin-right: 0; }

@media (min-width: 1280px) { .programm__tabs { width: 20%; display: inline-block; vertical-align: top; padding-top: 25px; } }

@media (min-width: 1900px) { .programm__tabs { width: 20%; display: inline-block; vertical-align: top; padding-top: 30px; } }

.programm__tabs-item { cursor: pointer; position: relative; margin: 35px 0 35px 70px; }

@media (min-width: 1900px) { .programm__tabs-item { margin: 37px 0 37px 110px; } }

.programm__tabs-item:before { content: ''; position: absolute; background: url("../img/pic/text_bg_black.png") no-repeat 50% 50%; background-size: 100% 100%; top: -19px; left: -55px; right: -160px; bottom: -27px; display: none; }

@media (min-width: 1900px) { .programm__tabs-item:before { top: -25px; left: -80px; right: -87px; bottom: -28px; } }

.programm__tabs-item.is-active:before { display: block; }

.programm__tabs-info { position: relative; }

.programm__tabs-num { font-size: 40px; position: absolute; top: -4px; left: -30px; }

@media (min-width: 1900px) { .programm__tabs-num { font-size: 50px; left: -50px; } }

.is-active .programm__tabs-num { color: #FF9900; }

.programm__tabs-title { font-size: 18px; margin-bottom: 5px; }

@media (min-width: 1900px) { .programm__tabs-title { font-size: 24px; } }

.is-active .programm__tabs-title { color: #FF9900; }

.programm__tabs-text { font-size: 12px; color: #000; margin-bottom: 5px; }

@media (min-width: 1900px) { .programm__tabs-text { font-size: 14px; } }

.programm__tabs-text span { color: #000; }

.is-active .programm__tabs-text { color: #fff; }

.is-active .programm__tabs-text span { color: #FF9900; }

@media (min-width: 1280px) { .programm__list { width: 80%; display: inline-block; vertical-align: top; } }

.programm__item { position: relative; }

.programm__item-picture { display: none; background: no-repeat 50% 50%; background-size: cover; }

@media (min-width: 1280px) { .programm__item-picture { display: block; background-color: #000; position: absolute; top: 0; left: 0; width: 25%; bottom: 0; } }

.programm__item-picture-age { font-size: 30px; color: #FF9900; position: absolute; height: 58px; line-height: 58px; min-width: 60px; text-align: center; padding: 0 15px; box-sizing: border-box; right: 0; bottom: 90px; background: url("../img/svg/age.svg") no-repeat 0 50%; background-size: auto 100%; }

@media (min-width: 1900px) { .programm__item-picture-age { font-size: 40px; height: 80px; line-height: 80px; min-width: 90px; bottom: 100px; } }

.programm__item-info { padding: 88px 20px 140px; box-sizing: border-box; min-height: 100vh; position: relative; }

@media (min-width: 768px) { .programm__item-info { padding: 120px 30px 170px; } }

@media (min-width: 1280px) { .programm__item-info { margin-left: 25%; padding: 50px 40px 90px 40px; min-height: calc(100vh - 190px); } }

@media (min-width: 1900px) { .programm__item-info { padding: 50px 40px 100px 60px; min-height: calc(100vh - 280px); } }

.programm__item-title { font-size: 24px; color: #FF9900; margin-bottom: 15px; margin-right: 110px; }

@media (min-width: 768px) { .programm__item-title { font-size: 31px; margin-bottom: 20px; } }

@media (min-width: 1280px) { .programm__item-title { font-size: 30px; } }

@media (min-width: 1900px) { .programm__item-title { font-size: 40px; } }

.programm__item-list { font-size: 12px; margin-bottom: 20px; }

@media (min-width: 768px) { .programm__item-list { font-size: 16px; margin-bottom: 70px; } }

@media (min-width: 1280px) { .programm__item-list { font-size: 12px; margin-bottom: 20px; } }

@media (min-width: 1900px) { .programm__item-list { font-size: 14px; } }

@media (min-width: 1280px) { .programm__item-list ul { columns: 300px 2; column-gap: 20px; } }

.programm__item-list li { padding-left: 21px; position: relative; margin: 0 0 5px 0; }

@media (min-width: 768px) { .programm__item-list li { margin: 0 0 10px 0; } }

.programm__item-list li:before { content: ''; display: block; position: absolute; top: 0; left: 0; width: 10px; height: 10px; background: url("../img/svg/star.svg") no-repeat 50% 50%; background-size: contain; }

@media (min-width: 768px) { .programm__item-list li:before { width: 13px; height: 13px; top: -1px; } }

@media (min-width: 1280px) { .programm__item-list li:before { width: 10px; height: 10px; } }

@media (min-width: 1900px) { .programm__item-list li:before { width: 13px; height: 13px; top: -1px; } }

.programm__item-course { margin-bottom: 20px; }

@media (min-width: 1280px) { .programm__item-course { margin-bottom: 30px; } }

.programm__item-course-title { font-size: 14px; margin-bottom: 10px; }

@media (min-width: 768px) { .programm__item-course-title { font-size: 21px; margin-bottom: 25px; } }

@media (min-width: 1280px) { .programm__item-course-title { font-size: 14px; } }

@media (min-width: 1900px) { .programm__item-course-title { font-size: 16px; } }

.programm__item-course-list { white-space: nowrap; font-size: 0; margin: 0 -20px; }

.programm__item-course-list .slick-arrow { position: absolute; top: -45px; right: 0; width: 28px; height: 28px; fill: #A8A8A8; display: inline-block; cursor: pointer; }

@media (min-width: 0) and (max-width: 1279px) { .programm__item-course-list .slick-arrow { display: none !important; } }

.programm__item-course-list .slick-arrow_prev { transform: rotate(-90deg); right: 95px; }

@media (min-width: 1900px) { .programm__item-course-list .slick-arrow_prev { right: 115px; } }

.programm__item-course-list .slick-arrow_next { transform: rotate(90deg); margin-top: -2px; right: 0; }

.programm__item-course-list .slick-arrow:hover { fill: #FF9900; }

@media (min-width: 1900px) { .programm__item-course-list .slick-arrow { width: 36px; height: 36px; } }

.programm__item-course-list .slick-arrow.slick-disabled { fill: #A8A8A8 !important; cursor: default; opacity: 0.5; }

.programm__item-course-list .slick-track { margin: 0; }

@media (min-width: 768px) { .programm__item-course-list { margin: 0 -30px 110px; } }

@media (min-width: 1280px) { .programm__item-course-list { margin: 0; } }

.programm__item-course-list::-webkit-scrollbar { display: none; }

.programm__item-course-item { white-space: normal; display: inline-block; vertical-align: top; padding-left: 20px; }

@media (min-width: 0) and (max-width: 767px) { .programm__item-course-item { width: 210px !important; } }

@media (min-width: 768px) { .programm__item-course-item { padding-left: 30px; } }

@media (min-width: 768px) and (max-width: 1279px) { .programm__item-course-item { width: 180px !important; } }

@media (min-width: 1280px) { .programm__item-course-item { padding-left: 0; padding-right: 20px; box-sizing: border-box; } }

.programm__item-course-image { width: 100px; float: left; }

@media (min-width: 768px) { .programm__item-course-image { width: 150px; height: 121px; float: none; margin: 0 auto 10px; } }

@media (min-width: 1280px) { .programm__item-course-image { width: 120px; height: 97px; } }

@media (min-width: 1900px) { .programm__item-course-image { width: 182px; height: 146px; } }

.programm__item-course-image img { width: 100%; display: block; }

.programm__item-course-name { font-size: 14px; color: #FF9900; margin-left: 110px; width: 100px; margin-bottom: 5px; }

@media (min-width: 768px) { .programm__item-course-name { margin-left: 0; width: auto; font-size: 18px; margin-bottom: 10px; } }

@media (min-width: 1280px) { .programm__item-course-name { font-size: 14px; } }

@media (min-width: 1900px) { .programm__item-course-name { font-size: 16px; } }

.programm__item-course-text { font-size: 12px; margin-left: 110px; width: 100px; }

@media (min-width: 768px) { .programm__item-course-text { margin-left: 0; width: auto; font-size: 16px; } }

@media (min-width: 1280px) { .programm__item-course-text { font-size: 12px; } }

@media (min-width: 1900px) { .programm__item-course-text { font-size: 14px; } }

.programm__item-price { font-size: 0; }

@media (min-width: 1280px) { .programm__item-price-item { display: inline-block; vertical-align: middle; width: 250px; } }

@media (min-width: 1900px) { .programm__item-price-item { width: 425px; } }

.programm__item-price-name { font-size: 12px; display: inline-block; vertical-align: baseline; width: calc(100% - 115px); margin-bottom: 7px; }

@media (min-width: 768px) { .programm__item-price-name { font-size: 16px; width: calc(100% - 280px); } }

@media (min-width: 1280px) { .programm__item-price-name { font-size: 12px; display: block; float: left; } }

@media (min-width: 1900px) { .programm__item-price-name { font-size: 14px; width: calc(100% - 260px); line-height: 1.4; } }

.programm__item-price-pay { font-size: 20px; font-weight: 600; color: #FF9900; display: inline-block; vertical-align: baseline; }

@media (min-width: 768px) { .programm__item-price-pay { font-size: 26px; } }

@media (min-width: 1280px) { .programm__item-price-pay { font-size: 20px; display: block; margin: 4px 0 4px 105px; } }

@media (min-width: 1900px) { .programm__item-price-pay { font-size: 30px; margin: 0px 0 5px 170px; } }

.programm__item-price-footnote { font-size: 12px; color: #000; display: inline-block; vertical-align: baseline; }

@media (min-width: 768px) { .programm__item-price-footnote { font-size: 12px; margin-left: 10px; } }

@media (min-width: 1280px) { .programm__item-price-footnote { font-size: 10px; display: block; margin-left: 105px; } }

@media (min-width: 1900px) { .programm__item-price-footnote { font-size: 12px; margin-left: 170px; } }

.programm__item-controls { position: absolute; bottom: 70px; left: 20px; right: 20px; padding-bottom: 5px; display: inline-block; font-size: 0; white-space: nowrap; }

@media (min-width: 768px) { .programm__item-controls { bottom: 90px; left: 30px; right: 30px; } }

@media (min-width: 1280px) { .programm__item-controls { display: inline-block; position: static; width: calc(100% - 500px); } }

@media (min-width: 1900px) { .programm__item-controls { width: calc(100% - 850px); margin-left: -15px; } }

.programm__item-controls .btn { padding: 15px 20px 16px; vertical-align: middle; font-size: 16px; }

.programm__item-controls .btn_white { margin-right: 9px; }

@media (min-width: 768px) { .programm__item-controls .btn_white { margin-right: 20px; } }

@media (min-width: 1280px) { .programm__item-controls .btn_white { display: none; } }

@media (min-width: 768px) { .programm__item-controls .btn { font-size: 20px; padding: 18px 40px 18px; width: auto; } }

@media (min-width: 1280px) { .programm__item-controls .btn { font-size: 12px; padding: 10px 20px 11px; width: auto; } }

@media (min-width: 1900px) { .programm__item-controls .btn { font-size: 16px; padding: 15px 30px 16px; } }

.license { position: relative; }

.license .inner { margin: 0; }

@media (min-width: 1280px) { .license .inner { padding-top: 110px; } }

@media (min-width: 1900px) { .license .inner { padding-top: 140px; } }

.license__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .license__title { font-size: 50px; margin-bottom: 20px; display: block; } }

@media (min-width: 1900px) { .license__title { font-size: 70px; margin-bottom: 60px; } }

.license__control { position: absolute; z-index: 1; right: 20px; top: 90px; white-space: nowrap; }

@media (min-width: 768px) { .license__control { right: 30px; top: 110px; } }

@media (min-width: 1280px) { .license__control { display: none; } }

.license__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.license__control-arrow:hover { fill: #FF9900; }

.license__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .license__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .license__control-arrow svg { width: 36px; height: 36px; } }

.license__control-arrow_prev svg { transform: rotate(-90deg); }

.license__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .license__control-arrow_next { margin-left: 80px; } }

.license__control-arrow_next svg { transform: rotate(90deg); }

.license__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.license__content { position: relative; font-size: 0; background: #f5f5f5; }

.license__content:before, .license__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.license__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.license__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.license__item { position: relative; min-height: 100vh; box-sizing: border-box; padding: 88px 20px 85px; background: #F1F1F1; width: 100vw !important; }

@media (min-width: 768px) { .license__item { padding: 190px 30px 85px; width: 345px !important; }
  .license__item_odd { background: #f5f5f5; } }

@media (min-width: 1280px) { .license__item { width: 20% !important; display: inline-block; vertical-align: top; padding: 40px 20px 70px; min-height: calc(100vh - 190px); } }

@media (min-width: 1900px) { .license__item { padding: 60px 40px 100px; min-height: calc(100vh - 285px); } }

.license__item-part { font-size: 24px; margin-right: 120px; }

@media (min-width: 768px) { .license__item-part { font-size: 31px; margin-right: 0; } }

@media (min-width: 1280px) { .license__item-part { font-size: 30px; } }

@media (min-width: 1900px) { .license__item-part { font-size: 40px; } }

.license__item-part span { color: #FF9900; }

@media (min-width: 1280px) { .license__item-part span { font-size: 40px; } }

@media (min-width: 1900px) { .license__item-part span { font-size: 50px; } }

.license__item-image { width: 241px; margin: 0 auto; min-height: calc(100vh - 310px); display: flex; align-items: center; justify-content: center; }

@media (min-width: 768px) { .license__item-image { width: 300px; height: 242px; min-height: calc(100vh - 620px); } }

@media (min-width: 1280px) { .license__item-image { width: 166px; min-height: calc(100vh - 550px); height: 200px; } }

@media (min-width: 1900px) { .license__item-image { width: 241px; min-height: calc(100vh - 690px); } }

.license__item-image img { width: 100%; }

.license__item-title { font-size: 14px; color: #FF9900; margin-bottom: 10px; }

@media (min-width: 768px) { .license__item-title { font-size: 18px; } }

@media (min-width: 1280px) { .license__item-title { font-size: 14px; } }

@media (min-width: 1900px) { .license__item-title { font-size: 16px; } }

.license__item-text { font-size: 12px; line-height: 1.5; }

@media (min-width: 768px) { .license__item-text { font-size: 16px; } }

@media (min-width: 1280px) { .license__item-text { font-size: 12px; } }

@media (min-width: 1900px) { .license__item-text { font-size: 14px; } }

.license__item-text a { color: #FF9900; }

.license__item-text a:hover { text-decoration: underline; }

.license__item-text li { position: relative; padding-left: 15px; }

.license__item-text li:before { content: ''; display: block; position: absolute; top: 2px; left: 0px; width: 10px; height: 10px; background: url("../img/svg/star.svg") no-repeat 50% 50%; background-size: contain; }

@media (min-width: 768px) { .license__item-text li:before { top: 6px; } }

@media (min-width: 1280px) { .license__item-text li:before { top: 2px; } }

@media (min-width: 1900px) { .license__item-text li:before { width: 12px; height: 12px; } }

.command { position: relative; }

.command .inner { margin: 0; }

@media (min-width: 1280px) { .command .inner { padding-top: 110px; } }

@media (min-width: 1900px) { .command .inner { padding-top: 135px; } }

@media (min-width: 1280px) { .command__head { margin-bottom: 20px; } }

.command__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .command__title { font-size: 50px; display: table-cell; vertical-align: top; padding: 0 30px; white-space: nowrap; } }

@media (min-width: 1900px) { .command__title { font-size: 70px; margin-bottom: 60px; } }

.command__info { color: #000; font-size: 12px; padding: 80px 20px 20px; }

@media (min-width: 768px) { .command__info { font-size: 16px; line-height: 1.5; padding: 105px 30px 30px; } }

@media (min-width: 1280px) { .command__info { display: table-cell; font-size: 12px; vertical-align: middle; padding: 10px 30px 0 0; line-height: 1.2; } }

.command__content { position: relative; font-size: 0; background: #F1F1F1; }

.command__content:before, .command__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.command__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.command__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.command__control { position: absolute; z-index: 1; right: 20px; top: 40px; white-space: nowrap; }

@media (min-width: 768px) { .command__control { right: 30px; top: 67px; } }

@media (min-width: 1280px) { .command__control { display: none; } }

.command__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.command__control-arrow:hover { fill: #FF9900; }

.command__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .command__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .command__control-arrow svg { width: 36px; height: 36px; } }

.command__control-arrow_prev svg { transform: rotate(-90deg); }

.command__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .command__control-arrow_next { margin-left: 80px; } }

.command__control-arrow_next svg { transform: rotate(90deg); }

.command__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.command__tabs { display: none; overflow: hidden; }

.command__tabs .mCSB_container { margin-right: 0; }

@media (min-width: 1280px) { .command__tabs { width: 20%; display: inline-block; vertical-align: top; padding-top: 25px; max-height: calc(100vh - 285px); overflow: auto; } }

@media (min-width: 1900px) { .command__tabs { width: 20%; display: inline-block; vertical-align: top; padding-top: 30px; max-height: calc(100vh - 350px); } }

.command__tabs-item { cursor: pointer; position: relative; margin: 30px 0 30px 70px; }

@media (min-width: 1900px) { .command__tabs-item { margin: 37px 0 37px 110px; } }

.command__tabs-item:before { content: ''; position: absolute; background: url("../img/pic/text_bg_black.png") no-repeat 50% 50%; background-size: 100% 100%; top: -19px; left: -60px; right: -100px; bottom: -23px; display: none; }

@media (min-width: 1900px) { .command__tabs-item:before { top: -25px; left: -80px; right: -87px; bottom: -28px; } }

.command__tabs-item.is-active:before { display: block; }

.command__tabs-info { position: relative; }

.command__tabs-photo { position: absolute; top: -2px; left: -45px; width: 36px; height: 36px; }

@media (min-width: 1900px) { .command__tabs-photo { width: 50px; height: 50px; left: -65px; top: 0; } }

.command__tabs-photo img { width: 100%; height: 100%; }

.command__tabs-title { font-size: 18px; margin-bottom: 5px; }

@media (min-width: 1900px) { .command__tabs-title { font-size: 24px; } }

.is-active .command__tabs-title { color: #FF9900; }

.command__tabs-text { font-size: 12px; color: #000; margin-bottom: 5px; }

@media (min-width: 1900px) { .command__tabs-text { font-size: 14px; } }

.command__tabs-text span { color: #000; }

.is-active .command__tabs-text { color: #fff; }

.is-active .command__tabs-text span { color: #FF9900; }

@media (min-width: 1280px) { .command__list { width: 80%; display: inline-block; vertical-align: top; } }

.command__item { position: relative; }

.command__item-picture { display: none; background: no-repeat 50% 50%; background-size: cover; }

@media (min-width: 1280px) { .command__item-picture { display: block; background-color: #000; position: absolute; top: 0; left: 0; width: 25%; bottom: 0; } }

.command__item-picture-age { font-size: 30px; color: #FF9900; position: absolute; height: 58px; line-height: 58px; min-width: 60px; text-align: center; padding: 0 15px; box-sizing: border-box; right: 0; bottom: 90px; background: url("../img/svg/age.svg") no-repeat 0 50%; background-size: auto 100%; }

@media (min-width: 1900px) { .command__item-picture-age { font-size: 40px; height: 80px; line-height: 80px; min-width: 90px; bottom: 100px; } }

.command__item-info { padding: 35px 20px 115px; box-sizing: border-box; position: relative; }

@media (min-width: 768px) { .command__item-info { padding: 45px 30px 80px; } }

@media (min-width: 1280px) { .command__item-info { margin-left: 25%; padding: 50px 40px 90px 40px; min-height: calc(100vh - 190px); } }

@media (min-width: 1900px) { .command__item-info { padding: 50px 40px 100px 60px; min-height: calc(100vh - 280px); } }

.command__item-head { overflow: hidden; margin-right: 110px; white-space: nowrap; margin-bottom: 15px; }

@media (min-width: 768px) { .command__item-head { margin-bottom: 20px; } }

.command__item-photo { width: 50px; height: 50px; overflow: hidden; border-radius: 50%; display: inline-block; vertical-align: middle; }

.command__item-photo img { width: 100%; height: 100%; }

@media (min-width: 768px) { .command__item-photo { width: 65px; height: 65px; } }

@media (min-width: 1280px) { .command__item-photo { display: none; } }

.command__item-title { display: inline-block; vertical-align: middle; font-size: 24px; color: #FF9900; margin-left: 10px; white-space: normal; }

@media (min-width: 768px) { .command__item-title { font-size: 31px; margin-left: 20px; } }

@media (min-width: 1280px) { .command__item-title { font-size: 30px; margin-left: 0; } }

@media (min-width: 1900px) { .command__item-title { font-size: 40px; } }

.command__item-list { font-size: 12px; margin-bottom: 20px; }

@media (min-width: 768px) { .command__item-list { font-size: 16px; margin-bottom: 70px; } }

@media (min-width: 1280px) { .command__item-list { font-size: 12px; margin-bottom: 20px; } }

@media (min-width: 1900px) { .command__item-list { font-size: 14px; } }

.command__item-list li { position: relative; margin: 0 0 5px 0; }

.command__item-course { margin-bottom: 20px; }

@media (min-width: 1280px) { .command__item-course { margin-bottom: 30px; } }

.command__item-course-title { font-size: 14px; margin-bottom: 10px; }

@media (min-width: 768px) { .command__item-course-title { font-size: 21px; margin-bottom: 25px; } }

@media (min-width: 1280px) { .command__item-course-title { font-size: 14px; } }

@media (min-width: 1900px) { .command__item-course-title { font-size: 16px; } }

.command__item-course-list { white-space: nowrap; font-size: 0; margin: 0 -20px; }

.command__item-course-list .slick-arrow { position: absolute; top: -45px; right: 0; width: 28px; height: 28px; fill: #A8A8A8; display: inline-block; cursor: pointer; }

@media (min-width: 0) and (max-width: 1279px) { .command__item-course-list .slick-arrow { display: none !important; } }

.command__item-course-list .slick-arrow_prev { transform: rotate(-90deg); right: 95px; }

@media (min-width: 1900px) { .command__item-course-list .slick-arrow_prev { right: 115px; } }

.command__item-course-list .slick-arrow_next { transform: rotate(90deg); margin-top: -2px; right: 0; }

.command__item-course-list .slick-arrow:hover { fill: #FF9900; }

@media (min-width: 1900px) { .command__item-course-list .slick-arrow { width: 36px; height: 36px; } }

.command__item-course-list .slick-arrow.slick-disabled { fill: #A8A8A8 !important; cursor: default; opacity: 0.5; }

.command__item-course-list .slick-track { margin: 0; }

@media (min-width: 768px) { .command__item-course-list { margin: 0 -30px 110px; } }

@media (min-width: 1280px) { .command__item-course-list { margin: 0; } }

.command__item-course-list::-webkit-scrollbar { display: none; }

.command__item-course-item { white-space: normal; display: inline-block; vertical-align: top; padding-left: 20px; }

@media (min-width: 0) and (max-width: 767px) { .command__item-course-item { width: 210px !important; } }

@media (min-width: 768px) { .command__item-course-item { padding-left: 30px; } }

@media (min-width: 768px) and (max-width: 1279px) { .command__item-course-item { width: 180px !important; } }

@media (min-width: 1280px) { .command__item-course-item { padding-left: 0; padding-right: 20px; box-sizing: border-box; } }

.command__item-course-image { width: 100px; float: left; }

@media (min-width: 768px) { .command__item-course-image { width: 150px; height: 121px; float: none; margin: 0 auto 10px; } }

@media (min-width: 1280px) { .command__item-course-image { width: 120px; height: 97px; } }

@media (min-width: 1900px) { .command__item-course-image { width: 182px; height: 146px; } }

.command__item-course-image img { width: 100%; display: block; }

.command__item-course-name { font-size: 14px; color: #FF9900; margin-left: 110px; width: 100px; margin-bottom: 5px; }

@media (min-width: 768px) { .command__item-course-name { margin-left: 0; width: auto; font-size: 18px; margin-bottom: 10px; } }

@media (min-width: 1280px) { .command__item-course-name { font-size: 14px; } }

@media (min-width: 1900px) { .command__item-course-name { font-size: 16px; } }

.command__item-course-text { font-size: 12px; margin-left: 110px; width: 100px; }

@media (min-width: 768px) { .command__item-course-text { margin-left: 0; width: auto; font-size: 16px; } }

@media (min-width: 1280px) { .command__item-course-text { font-size: 12px; } }

@media (min-width: 1900px) { .command__item-course-text { font-size: 14px; } }

.command__item-controls { position: absolute; bottom: 70px; left: 20px; right: 20px; padding-bottom: 5px; display: inline-block; font-size: 0; white-space: nowrap; }

@media (min-width: 768px) { .command__item-controls { bottom: 90px; left: 30px; right: 30px; } }

@media (min-width: 1280px) { .command__item-controls { display: inline-block; position: static; width: calc(100% - 500px); } }

@media (min-width: 1900px) { .command__item-controls { width: calc(100% - 850px); margin-left: -15px; } }

.command__item-controls .btn { padding: 15px 20px 16px; vertical-align: middle; font-size: 16px; }

@media (min-width: 0) and (max-width: 767px) { .command__item-controls .btn_grad { width: 209px; white-space: normal; padding: 6px 20px; } }

.command__item-controls .btn_white { margin-right: 9px; }

@media (min-width: 768px) { .command__item-controls .btn_white { margin-right: 20px; } }

@media (min-width: 1280px) { .command__item-controls .btn_white { display: none; } }

@media (min-width: 768px) { .command__item-controls .btn { font-size: 20px; padding: 18px 40px 18px; width: auto; } }

@media (min-width: 1280px) { .command__item-controls .btn { font-size: 12px; padding: 10px 20px 11px; width: auto; } }

@media (min-width: 1900px) { .command__item-controls .btn { font-size: 16px; padding: 15px 30px 16px; } }

.feedback { position: relative; }

.feedback .inner { margin: 0; }

@media (min-width: 1280px) { .feedback .inner { padding-top: 110px; } }

@media (min-width: 1900px) { .feedback .inner { padding-top: 140px; } }

.feedback__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .feedback__title { font-size: 50px; margin-bottom: 20px; display: block; } }

@media (min-width: 1900px) { .feedback__title { font-size: 70px; margin-bottom: 35px; } }

.feedback__control { position: absolute; z-index: 1; right: 20px; top: 90px; white-space: nowrap; }

@media (min-width: 768px) { .feedback__control { right: 30px; top: 130px; } }

@media (min-width: 1280px) { .feedback__control { top: -60px; } }

@media (min-width: 1900px) { .feedback__control { top: -95px; } }

.feedback__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.feedback__control-arrow:hover { fill: #FF9900; }

.feedback__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .feedback__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .feedback__control-arrow svg { width: 36px; height: 36px; } }

.feedback__control-arrow_prev svg { transform: rotate(-90deg); }

.feedback__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .feedback__control-arrow_next { margin-left: 80px; } }

.feedback__control-arrow_next svg { transform: rotate(90deg); }

.feedback__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.feedback__content { position: relative; font-size: 0; }

.feedback__content:before, .feedback__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.feedback__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.feedback__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.feedback__list { background: #F1F1F1; }

.feedback__item { position: relative; min-height: 100vh; box-sizing: border-box; padding: 83px 20px 85px; width: 100vw !important; }

@media (min-width: 768px) { .feedback__item { padding: 95px 30px 85px; }
  .feedback__item_odd { background: #f5f5f5; } }

@media (min-width: 1280px) { .feedback__item { width: 512px !important; padding: 20px 30px 70px; min-height: 0; } }

@media (min-width: 1900px) { .feedback__item { width: 734px !important; padding: 60px 40px 100px; min-height: calc(100vh - 285px); } }

.feedback__item-info { background: #fff; position: relative; padding: 5px 20px; margin: 0 -20px; }

@media (min-width: 768px) { .feedback__item-info { padding: 20px 30px; margin: 0 -30px; } }

@media (min-width: 1280px) { .feedback__item-info { margin: 0; position: relative; left: 13px; background: url("../img/pic/text_bg_white.png") no-repeat 50% 50%; background-size: 100% 100%; width: 356px; box-sizing: border-box; padding: 20px 82px 22px 31px; min-height: 115px; margin-left: -30px; } }

@media (min-width: 1900px) { .feedback__item-info { width: 530px; padding: 35px 50px 41px 40px; } }

.feedback__item-info:before, .feedback__item-info:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

@media (min-width: 1280px) { .feedback__item-info:before, .feedback__item-info:after { display: none; } }

.feedback__item-info:before { top: -19px; background-image: url("../img/svg/adge_top_white.svg"); }

.feedback__item-info:after { bottom: -19px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.feedback__item-info-photo { width: 50px; height: 50px; border-radius: 50%; overflow: hidden; margin-bottom: 10px; display: inline-block; vertical-align: middle; margin-right: 10px; }

@media (min-width: 768px) { .feedback__item-info-photo { width: 65px; height: 65px; } }

@media (min-width: 1280px) { .feedback__item-info-photo { width: 36px; height: 36px; position: absolute; top: 21px; left: 20px; }
  .feedback__item-info-photo ~ * { margin-left: 40px; } }

@media (min-width: 1900px) { .feedback__item-info-photo { width: 50px; height: 50px; top: 36px; left: 20px; } }

.feedback__item-info-photo img { width: 100%; height: 100%; }

.feedback__item-info-name { font-size: 24px; text-transform: uppercase; vertical-align: middle; display: inline-block; width: calc(100% - 170px); margin-bottom: 10px; }

@media (min-width: 768px) { .feedback__item-info-name { font-size: 31px; } }

@media (min-width: 1280px) { .feedback__item-info-name { font-size: 18px; width: auto; } }

@media (min-width: 1900px) { .feedback__item-info-name { font-size: 40px; } }

.feedback__item-info-title { font-size: 14px; color: #FF9900; margin-bottom: 5px; }

@media (min-width: 768px) { .feedback__item-info-title { font-size: 16px; } }

@media (min-width: 1280px) { .feedback__item-info-title { font-size: 14px; } }

@media (min-width: 1900px) { .feedback__item-info-title { font-size: 16px; display: inline-block; } }

.feedback__item-info-text { font-size: 12px; color: #A8A8A8; }

@media (min-width: 768px) { .feedback__item-info-text { font-size: 16px; } }

@media (min-width: 1280px) { .feedback__item-info-text { font-size: 12px; } }

@media (min-width: 1900px) { .feedback__item-info-text { font-size: 14px; display: inline-block; margin-left: 20px; } }

.feedback__item-body { padding: 20px 0; max-height: calc(100vh - 400px); min-height: 100px; margin-bottom: 20px; overflow: hidden; }

@media (min-width: 768px) { .feedback__item-body { max-height: calc(100vh - 490px); padding: 40px 0 10px; } }

@media (min-width: 1280px) { .feedback__item-body { overflow: auto; padding: 10px 0 10px; max-height: calc(100vh - 470px); } }

@media (min-width: 1900px) { .feedback__item-body { max-height: calc(100vh - 650px); } }

.feedback__item-body-title { font-size: 14px; color: #FF9900; margin-bottom: 5px; }

@media (min-width: 768px) { .feedback__item-body-title { font-size: 18px; margin-bottom: 15px; } }

@media (min-width: 1280px) { .feedback__item-body-title { font-size: 14px; } }

@media (min-width: 1900px) { .feedback__item-body-title { font-size: 16px; } }

.feedback__item-body-text { font-size: 12px; color: #000; line-height: 1.5; }

@media (min-width: 768px) { .feedback__item-body-text { font-size: 16px; } }

@media (min-width: 1280px) { .feedback__item-body-text { font-size: 12px; } }

@media (min-width: 1900px) { .feedback__item-body-text { font-size: 14px; } }

.feedback__item-btn .btn { display: block; }

@media (min-width: 768px) { .feedback__item-btn .btn { display: inline-block; } }

@media (min-width: 1280px) { .feedback__item-btn .btn { display: none; } }

.feedback__item-date { display: none; }

@media (min-width: 1280px) { .feedback__item-date { display: block; font-size: 12px; color: #000; } }

.vehicle { position: relative; }

.vehicle .inner { margin: 0; }

@media (min-width: 1280px) { .vehicle .inner { padding-top: 110px; } }

@media (min-width: 1900px) { .vehicle .inner { padding-top: 140px; } }

.vehicle__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .vehicle__title { font-size: 50px; margin-bottom: 20px; display: block; } }

@media (min-width: 1900px) { .vehicle__title { font-size: 70px; margin-bottom: 35px; } }

.vehicle__control { position: absolute; z-index: 1; right: 20px; top: 86px; white-space: nowrap; }

@media (min-width: 768px) { .vehicle__control { right: 30px; top: 130px; } }

@media (min-width: 1280px) { .vehicle__control { top: -60px; } }

@media (min-width: 1900px) { .vehicle__control { top: -95px; } }

.vehicle__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.vehicle__control-arrow:hover { fill: #FF9900; }

.vehicle__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .vehicle__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .vehicle__control-arrow svg { width: 36px; height: 36px; } }

.vehicle__control-arrow_prev svg { transform: rotate(-90deg); }

.vehicle__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .vehicle__control-arrow_next { margin-left: 80px; } }

.vehicle__control-arrow_next svg { transform: rotate(90deg); }

.vehicle__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.vehicle__content { position: relative; font-size: 0; }

.vehicle__content:before, .vehicle__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.vehicle__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.vehicle__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.vehicle__list { background: #F1F1F1; }

.vehicle__item { position: relative; min-height: 100vh; box-sizing: border-box; padding: 83px 20px 85px; width: 100vw !important; }

@media (min-width: 768px) { .vehicle__item { padding: 200px 30px 85px; width: 675px !important; }
  .vehicle__item_odd { background: #f5f5f5; } }

@media (min-width: 1280px) { .vehicle__item { width: 512px !important; padding: 40px 30px 70px; min-height: 0; } }

@media (min-width: 1900px) { .vehicle__item { width: 734px !important; padding: 60px 40px 100px; min-height: calc(100vh - 285px); } }

.vehicle__item-title { font-size: 24px; color: #FF9900; margin-bottom: 10px; margin-right: 120px; }

@media (min-width: 768px) { .vehicle__item-title { font-size: 31px; } }

@media (min-width: 1280px) { .vehicle__item-title { font-size: 30px; } }

@media (min-width: 1900px) { .vehicle__item-title { font-size: 40px; } }

.vehicle__item-text { font-size: 12px; color: #000; }

@media (min-width: 768px) { .vehicle__item-text { font-size: 16px; } }

@media (min-width: 1280px) { .vehicle__item-text { font-size: 12px; } }

@media (min-width: 1900px) { .vehicle__item-text { font-size: 14px; } }

.vehicle__item-image { margin: 0 auto; min-height: calc(100vh - 310px); display: flex; align-items: center; justify-content: center; }

@media (min-width: 768px) { .vehicle__item-image { min-height: calc(100vh - 510px); } }

@media (min-width: 1280px) { .vehicle__item-image { min-height: calc(100vh - 420px); } }

@media (min-width: 1900px) { .vehicle__item-image { min-height: calc(100vh - 560px); } }

.vehicle__item-image img { max-width: 100%; max-height: 100%; display: block; }

@media (min-width: 768px) { .vehicle__item-image img { max-height: calc(100vh - 600px); } }

@media (min-width: 1280px) { .vehicle__item-image img { max-height: calc(100vh - 470px); } }

@media (min-width: 1900px) { .vehicle__item-image img { max-height: calc(100vh - 570px); } }

.vehicle__item-info ul { font-size: 0; }

.vehicle__item-info li { position: relative; padding-left: 15px; display: inline-block; vertical-align: middle; width: 50%; box-sizing: border-box; margin-bottom: 10px; padding-right: 10px; }

@media (min-width: 768px) { .vehicle__item-info li { padding-left: 20px; } }

@media (min-width: 1280px) { .vehicle__item-info li { width: auto; padding-left: 15px; } }

@media (min-width: 1900px) { .vehicle__item-info li { top: 4px; width: 25%; padding-left: 15px; } }

.vehicle__item-info li:before { content: ''; display: block; position: absolute; top: 4px; left: 0px; width: 10px; height: 10px; background: url("../img/svg/star.svg") no-repeat 50% 50%; background-size: contain; }

@media (min-width: 768px) { .vehicle__item-info li:before { width: 13px; height: 13px; top: 6px; } }

@media (min-width: 1280px) { .vehicle__item-info li:before { width: 10px; height: 10px; } }

.vehicle__item-info-title { font-size: 16px; margin-bottom: 5px; }

@media (min-width: 768px) { .vehicle__item-info-title { font-size: 21px; } }

@media (min-width: 1280px) { .vehicle__item-info-title { font-size: 14px; } }

@media (min-width: 1900px) { .vehicle__item-info-title { font-size: 16px; } }

.vehicle__item-info-text { font-size: 12px; color: #000; }

@media (min-width: 768px) { .vehicle__item-info-text { font-size: 16px; } }

@media (min-width: 1280px) { .vehicle__item-info-text { font-size: 10px; } }

@media (min-width: 1900px) { .vehicle__item-info-text { font-size: 12px; } }

.contact { position: relative; }

.contact .inner { margin: 0; }

@media (min-width: 1280px) { .contact .inner { padding-top: 110px; } }

@media (min-width: 1900px) { .contact .inner { padding-top: 140px; } }

.contact__title { display: none; font-size: 50px; color: #000; margin: 0 40px 20px; }

@media (min-width: 1280px) { .contact__title { font-size: 50px; margin-bottom: 20px; display: block; } }

@media (min-width: 1900px) { .contact__title { font-size: 70px; margin-bottom: 35px; } }

.contact__control { position: absolute; z-index: 1; right: 20px; top: 100px; white-space: nowrap; }

@media (min-width: 768px) { .contact__control { right: 30px; top: 145px; } }

@media (min-width: 1280px) { .contact__control { right: 56px; top: 46px; } }

@media (min-width: 1900px) { .contact__control { right: 90px; top: 72px; } }

.contact__control-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; }

.contact__control-arrow:hover { fill: #FF9900; }

.contact__control-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .contact__control-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .contact__control-arrow svg { width: 36px; height: 36px; } }

.contact__control-arrow_prev svg { transform: rotate(-90deg); }

.contact__control-arrow_next { margin-left: 60px; }

@media (min-width: 768px) { .contact__control-arrow_next { margin-left: 80px; } }

@media (min-width: 1280px) { .contact__control-arrow_next { margin-left: 60px; } }

.contact__control-arrow_next svg { transform: rotate(90deg); }

.contact__control-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.contact__item { position: relative; padding: 85px 0 0 0; min-height: 100vh; box-sizing: border-box; }

@media (min-width: 768px) { .contact__item { padding-top: 100px; } }

@media (min-width: 1280px) { .contact__item { padding: 0; height: calc(100vh - 230px); min-height: 400px; } }

.contact__content { position: relative; }

.contact__content:before, .contact__content:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.contact__content:before { top: -2px; background-image: url("../img/svg/adge_bottom_white.svg"); }

.contact__content:after { bottom: -2px; background-image: url("../img/svg/adge_top_white.svg"); }

.contact__map { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #A8A8A8; }

.contact__map-btn { position: absolute; bottom: 75px; left: 20px; right: 20px; }

@media (min-width: 768px) { .contact__map-btn { left: 30px; right: 30px; bottom: 100px; } }

@media (min-width: 1280px) { .contact__map-btn { display: none; } }

.contact__map-btn .btn { display: block; }

@media (min-width: 768px) { .contact__map-btn .btn { display: inline-block; } }

.contact__info { padding: 15px 20px; position: relative; background: #000; color: #fff; margin-bottom: 300px; }

@media (min-width: 768px) { .contact__info { padding: 40px 30px; } }

@media (min-width: 1280px) { .contact__info { position: absolute; top: 0; right: 30px; bottom: 0; width: 480px; padding: 50px 40px; margin-bottom: 0; } }

@media (min-width: 1900px) { .contact__info { width: 730px; right: 40px; padding: 70px 50px 50px; } }

.contact__info:before, .contact__info:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; }

@media (min-width: 1280px) { .contact__info:before, .contact__info:after { display: none; } }

.contact__info:before { bottom: calc(100% - 2px); background-image: url("../img/svg/adge_top_black.svg"); }

.contact__info:after { top: calc(100% - 2px); background-image: url("../img/svg/adge_bottom_black.svg"); }

.contact__info-title { font-size: 24px; font-weight: 600; margin-bottom: 20px; }

@media (min-width: 768px) { .contact__info-title { font-size: 31px; margin-bottom: 25px; } }

@media (min-width: 1280px) { .contact__info-title { font-size: 20px; } }

@media (min-width: 1900px) { .contact__info-title { font-size: 30px; margin-bottom: 35px; } }

.contact__info-item { padding-left: 30px; line-height: 1.4; margin-bottom: 10px; position: relative; }

@media (min-width: 768px) { .contact__info-item { padding-left: 44px; margin-bottom: 30px; } }

@media (min-width: 1280px) { .contact__info-item { padding-left: 30px; margin-bottom: 25px; } }

.contact__info-item:last-child { margin-bottom: 0; }

.contact__info-item-ico { position: absolute; fill: #FF9900; width: 20px; height: 20px; top: -3px; left: 0; }

@media (min-width: 768px) { .contact__info-item-ico { width: 25px; height: 25px; top: -3px; } }

@media (min-width: 1280px) { .contact__info-item-ico { width: 20px; height: 20px; } }

.contact__info-item-ico svg { width: 100%; height: 100%; }

.contact__info-item-title { color: #FF9900; font-size: 12px; }

@media (min-width: 768px) { .contact__info-item-title { font-size: 16px; } }

@media (min-width: 1280px) { .contact__info-item-title { font-size: 12px; margin-bottom: 5px; } }

@media (min-width: 1900px) { .contact__info-item-title { font-size: 14px; } }

.contact__info-item-text { font-family: 'Roboto Slab', serif; font-size: 14px; }

@media (min-width: 768px) { .contact__info-item-text { font-size: 21px; } }

@media (min-width: 1280px) { .contact__info-item-text { font-size: 14px; } }

@media (min-width: 1900px) { .contact__info-item-text { font-size: 16px; } }

.contact__info-item-text span { display: inline-block; padding: 3px 5px; }

.contact__info-item-metro { display: none; margin-top: 10px; }

.contact__info-item-metro li { font-size: 14px; position: relative; margin: 5px 0; }

.contact__info-item-metro li:before { content: ''; width: 16px; display: inline-block; vertical-align: middle; margin-right: 5px; height: 16px; background: url("../img/svg/metro.svg") no-repeat 50% 50%; background-size: contain; }

.contact__info-item-metro li a { color: #FF9900; display: inline-block; margin-left: 10px; text-decoration: underline; }

.contact__info-item-metro li a:hover { text-decoration: none; }

@media (min-width: 1280px) { .contact__info-item-metro { display: block; } }

@media (min-width: 1900px) { .contact__info-item-metro { font-size: 16px; } }

@media (min-width: 768px) { .contact__info-footer { margin: 50px 0 20px; } }

@media (min-width: 1280px) { .contact__info-footer { margin: 100px 0 0; } }

.contact__info-cta { margin: 45px 0; }

@media (min-width: 768px) { .contact__info-cta { display: inline-block; vertical-align: middle; margin: 0 60px 0 0; } }

@media (min-width: 1280px) { .contact__info-cta { margin-bottom: 20px; display: block; } }

.contact__info-cta .btn { display: block; font-size: 18px; padding: 12px 40px 14px; }

@media (min-width: 768px) { .contact__info-cta .btn { font-size: 20px; padding: 17px 40px 19px; } }

@media (min-width: 1280px) { .contact__info-cta .btn { font-size: 12px; padding: 10px 20px 11px; display: inline-block; } }

@media (min-width: 1280px) { .contact__info .js-scrollbar { height: calc(100vh - 330px); } }

@media (min-width: 1900px) { .contact__info .js-scrollbar { height: calc(100vh - 435px); } }

.map { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

.fullpage-wrapper { width: 100% !important; transform: none !important; }

.fp-section { width: 100% !important; position: absolute; left: 0; top: 0; visibility: hidden; opacity: 0; z-index: 0; -webkit-transition: all 0.15s ease 0s; -khtml-transition: all 0.15s ease 0s; -moz-transition: all 0.15s ease 0s; -ms-transition: all 0.15s ease 0s; -o-transition: all 0.15s ease 0s; transition: all 0.15s ease 0s; }

.fp-section.active { visibility: visible; opacity: 1; z-index: 1; }

.popup { display: none; }

.popup.is-open { display: block !important; }

.popup__overlay { background-color: rgba(0, 0, 0, 0.8); display: block; height: 100%; left: 0; position: fixed; top: 0; width: 100%; z-index: 100; }

.popup__inner { height: 100%; left: 0; overflow: auto; position: fixed; text-align: center; top: 0; width: 100%; z-index: 100; display: block; background: transparent; overflow-y: scroll; font-size: 0; }

.popup__inner:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; }

.popup__layout { background-color: white; box-sizing: border-box; display: inline-block; margin: 0 auto; padding: 0; position: relative; vertical-align: middle; width: 100%; max-width: calc(100% - 40px); min-height: 100px; text-align: left; font-size: 16px; -webkit-border-radius: 4px; -khtml-border-radius: 4px; -moz-border-radius: 4px; -ms-border-radius: 4px; -o-border-radius: 4px; border-radius: 4px; }

@media (min-width: 768px) { .popup__layout { max-width: 448px; } }

@media (min-width: 1280px) { .popup__layout { max-width: 530px; -webkit-border-radius: 5px 0 5px 5px; -khtml-border-radius: 5px 0 5px 5px; -moz-border-radius: 5px 0 5px 5px; -ms-border-radius: 5px 0 5px 5px; -o-border-radius: 5px 0 5px 5px; border-radius: 5px 0 5px 5px; } }

@media (min-width: 1900px) { .popup__layout { max-width: 764px; } }

.popup__close { fill: #FF9900; position: fixed; width: 74px; height: 74px; top: 0px; right: 0px; overflow: hidden; cursor: pointer; z-index: 10; }

@media (min-width: 1280px) { .popup__close { left: 100%; position: absolute; width: 75px; } }

@media (min-width: 1900px) { .popup__close { width: 95px; height: 95px; } }

.popup__close:hover { fill: #FFB240; }

.popup__close i { pointer-events: none; }

.popup__close i:before, .popup__close i:after { content: ''; display: block; position: absolute; background: #fff; top: 45%; left: 56%; height: 2px; width: 22px; margin-top: -2px; margin-left: -11px; border-radius: 10px; }

@media (min-width: 1280px) { .popup__close i:before, .popup__close i:after { top: 48%; left: 46%; } }

@media (min-width: 1900px) { .popup__close i:before, .popup__close i:after { top: 45%; left: 46%; width: 30px; height: 3px; margin-top: -1.5px; margin-left: -15px; } }

.popup__close i:before { transform: rotate(45deg); }

.popup__close i:after { transform: rotate(-45deg); }

.popup__close svg { pointer-events: none; height: 100%; position: absolute; top: -5px; left: -13px; transition: fill 0.3s ease; }

@media (min-width: 1280px) { .popup__close svg { left: auto; right: -13px; } }

@media (min-width: 1900px) { .popup__close svg { right: 0; } }

.popup__content { padding: 30px 20px; }

@media (min-width: 768px) { .popup__content { padding: 40px 30px; } }

@media (min-width: 1280px) { .popup__content { padding: 50px 40px; } }

@media (min-width: 1900px) { .popup__content { padding: 60px 55px; } }

.popup__title { font-size: 24px; margin-bottom: 15px; }

@media (min-width: 768px) { .popup__title { font-size: 31px; margin-bottom: 25px; } }

@media (min-width: 1280px) { .popup__title { font-size: 50px; margin-bottom: 40px; } }

@media (min-width: 1900px) { .popup__title { font-size: 70px; margin-bottom: 50px; } }

.popup__title span { color: #FF9900; }

.popup__text { font-size: 12px; line-height: 1.5; }

@media (min-width: 768px) { .popup__text { font-size: 16px; } }

@media (min-width: 1280px) { .popup__text { font-size: 12px; } }

@media (min-width: 1900px) { .popup__text { font-size: 14px; } }

@media (min-width: 0) and (max-width: 767px) { .popup .btn { font-size: 16px; padding: 14px 30px 17px; display: inline-block; } }

@media (min-width: 1280px) { .popup__form .form { margin: 0 -15px; } }

@media (min-width: 768px) { .popup__form .form__field { width: 282px; margin: 0 auto; } }

@media (min-width: 1280px) { .popup__form .form__field { width: 50%; padding: 0 15px; display: inline-block; vertical-align: top; box-sizing: border-box; } }

.popup__form .form__field_submit { margin-top: 35px; }

@media (min-width: 768px) { .popup__form .form__field_submit { width: auto; } }

.popup__form .form__field_submit .btn { width: 135px; display: inline-block; margin-right: 5px; padding-left: 10px; padding-right: 10px; }

@media (min-width: 768px) { .popup__form .form__field_submit .btn { width: 199px; margin-right: 20px; } }

@media (min-width: 1280px) { .popup__form .form__field_submit .btn { width: 111px; margin-right: 30px; } }

@media (min-width: 1900px) { .popup__form .form__field_submit .btn { width: 197px; margin-right: 60px; } }

.popup__form .form__field_submit .footnote { font-size: 10px; line-height: 1.5; display: inline-block; vertical-align: middle; color: #A8A8A8; width: calc(100% - 150px); }

@media (min-width: 768px) { .popup__form .form__field_submit .footnote { font-size: 12px; width: calc(100% - 225px); } }

@media (min-width: 1900px) { .popup__form .form__field_submit .footnote { font-size: 14px; width: calc(100% - 285px); } }

.popup_order .popup__title { text-align: center; }

.popup_info .popup__layout { background-image: url("../img/svg/record.svg"); background-position: 100% 100%; background-repeat: no-repeat; }

@media (min-width: 1280px) { .popup_feedback { display: none !important; } }

.popup_feedback .popup__layout { max-width: 100%; -webkit-border-radius: 0; -khtml-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; border-radius: 0; min-height: 100%; }

.popup_feedback .popup__content { padding-right: 80px; }

@media (min-width: 768px) { .popup_feedback .popup__content { padding-right: 100px; } }

.popup_gallery .popup__overlay { background: #000; }

.popup_gallery .popup__layout { max-width: 100%; -webkit-border-radius: 0; -khtml-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; border-radius: 0; background: transparent; }

@media (min-width: 768px) { .popup_gallery .popup__layout { max-width: 615px; } }

@media (min-width: 1280px) { .popup_gallery .popup__layout { max-width: 988px; } }

@media (min-width: 1900px) { .popup_gallery .popup__layout { max-width: 1400px; } }

.popup_gallery .popup__content { padding: 0; }

.popup_gallery .popup__photo { position: relative; }

.popup_gallery .popup__photo:before, .popup_gallery .popup__photo:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.popup_gallery .popup__photo:before { top: -2px; background-image: url("../img/svg/adge_bottom_black.svg"); }

.popup_gallery .popup__photo:after { bottom: -2px; background-image: url("../img/svg/adge_top_black.svg"); }

.popup_gallery .popup__photo-controls-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; position: absolute; bottom: 20px; z-index: 1; }

@media (min-width: 768px) { .popup_gallery .popup__photo-controls-arrow { bottom: 50%; margin-bottom: -14px; } }

.popup_gallery .popup__photo-controls-arrow:hover { fill: #FF9900; }

.popup_gallery .popup__photo-controls-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .popup_gallery .popup__photo-controls-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .popup_gallery .popup__photo-controls-arrow svg { width: 36px; height: 36px; } }

.popup_gallery .popup__photo-controls-arrow_prev { left: 20px; }

@media (min-width: 768px) { .popup_gallery .popup__photo-controls-arrow_prev { left: -50px; } }

.popup_gallery .popup__photo-controls-arrow_prev svg { transform: rotate(-90deg); }

.popup_gallery .popup__photo-controls-arrow_next { right: 20px; }

@media (min-width: 768px) { .popup_gallery .popup__photo-controls-arrow_next { right: -50px; } }

.popup_gallery .popup__photo-controls-arrow_next svg { transform: rotate(90deg); }

.popup_gallery .popup__photo-controls-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.popup_gallery .popup__photo-item { vertical-align: middle; }

.popup_gallery .popup__photo-item img { max-width: 100%; max-height: calc(100vh - 100px); display: block; margin: 0 auto; }

.popup_gallery .popup__inner { overflow: auto; }

@media (min-width: 1280px) { .popup_gallery .popup__close { position: fixed; left: auto; } }

@media (min-width: 1900px) { .popup_gallery .popup__close { width: 95px; height: 95px; } }

.popup_gallery .popup__close:hover { fill: #FFB240; }

@media (min-width: 1280px) { .popup_gallery .popup__close i:before, .popup_gallery .popup__close i:after { top: 45%; left: 56%; } }

.popup_gallery .popup__close svg { left: -13px; right: auto; }

@media (min-width: 1280px) { .popup_detail .popup__close { position: fixed; left: auto; } }

@media (min-width: 1900px) { .popup_detail .popup__close { width: 95px; height: 95px; } }

.popup_detail .popup__close:hover { fill: #FFB240; }

@media (min-width: 1280px) { .popup_detail .popup__close i:before, .popup_detail .popup__close i:after { top: 45%; left: 56%; } }

.popup_detail .popup__close svg { left: -13px; right: auto; }

@media (min-width: 1900px) { .popup_detail .popup__close svg { left: 0px; } }

.popup_detail .popup__overlay { background: #000; }

.popup_detail .popup__layout { min-height: 100%; min-width: 100%; background: transparent; padding: 30px 0; }

.popup_detail .popup__photo { position: relative; margin-bottom: 20px; }

.popup_detail .popup__photo-controls-arrow { fill: #A8A8A8; display: inline-block; cursor: pointer; position: absolute; bottom: 4px; z-index: 1; }

@media (min-width: 768px) { .popup_detail .popup__photo-controls-arrow { bottom: 50%; margin-bottom: -14px; } }

.popup_detail .popup__photo-controls-arrow:hover { fill: #FF9900; }

.popup_detail .popup__photo-controls-arrow svg { width: 22px; height: 22px; position: relative; }

@media (min-width: 768px) { .popup_detail .popup__photo-controls-arrow svg { width: 28px; height: 28px; } }

@media (min-width: 1900px) { .popup_detail .popup__photo-controls-arrow svg { width: 36px; height: 36px; } }

.popup_detail .popup__photo-controls-arrow_prev { left: 0px; }

@media (min-width: 768px) { .popup_detail .popup__photo-controls-arrow_prev { left: -50px; } }

.popup_detail .popup__photo-controls-arrow_prev svg { transform: rotate(-90deg); }

.popup_detail .popup__photo-controls-arrow_next { right: 0px; }

@media (min-width: 768px) { .popup_detail .popup__photo-controls-arrow_next { right: -50px; } }

.popup_detail .popup__photo-controls-arrow_next svg { transform: rotate(90deg); }

.popup_detail .popup__photo-controls-arrow.slick-disabled { opacity: 0.5; cursor: default; fill: #A8A8A8; }

.popup_detail .popup__photo-item { vertical-align: middle; position: relative; }

.popup_detail .popup__photo-item:before, .popup_detail .popup__photo-item:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.popup_detail .popup__photo-item:before { top: -2px; background-image: url("../img/svg/adge_bottom_black.svg"); }

.popup_detail .popup__photo-item:after { bottom: -2px; background-image: url("../img/svg/adge_top_black.svg"); }

.popup_detail .popup__photo-item img { display: block; margin: 0 auto; max-height: calc(100vh - 230px); width: auto; max-width: 100%; }

.popup_detail .popup__photo .slick-dots { text-align: center; top: 100%; left: 35px; right: 35px; }

.popup_detail .popup__photo .slick-dots li { display: inline-block; vertical-align: middle; fill: #A8A8A8; cursor: pointer; margin: 10px 5px; }

.popup_detail .popup__photo .slick-dots li.slick-active { fill: #FF9900; }

.popup_detail .popup__photo .slick-dots button { display: block; }

.popup_detail .popup__photo .slick-dots svg { width: 20px; height: 20px; display: block; }

.popup_detail .popup__title { color: #FF9900; margin-top: 30px; }

.popup_detail .popup__text { color: #fff; }

.popup_detail .popup__content { position: relative; padding: 0 20px; }

@media (min-width: 768px) { .popup_detail .popup__content { padding: 0; max-width: 615px; margin: 0 auto; } }

@media (min-width: 1280px) { .popup_detail .popup__content { max-width: 970px; } }

@media (min-width: 1900px) { .popup_detail .popup__content { max-width: 1214px; } }

.popup_detail .popup__course .popup__title { padding: 0 20px; }

@media (min-width: 768px) { .popup_detail .popup__course .popup__title { padding: 0; margin-right: auto; margin-left: auto; max-width: 615px; margin-bottom: 15px; } }

@media (min-width: 1280px) { .popup_detail .popup__course .popup__title { max-width: 970px; font-size: 30px; margin-bottom: 20px; } }

@media (min-width: 1900px) { .popup_detail .popup__course .popup__title { max-width: 1214px; font-size: 40px; } }

.popup_detail .popup__course-inner { position: relative; padding: 0 20px; }

@media (min-width: 768px) { .popup_detail .popup__course-inner { max-width: 615px; padding: 0; margin: 0 auto; } }

@media (min-width: 1280px) { .popup_detail .popup__course-inner { max-width: 970px; width: 50%; height: 100%; padding: 40px 30px; box-sizing: border-box; } }

@media (min-width: 1900px) { .popup_detail .popup__course-inner { max-width: 1214px; } }

.popup_detail .popup__course-image { background: no-repeat 50% 50%; background-size: cover; }

@media (min-width: 1280px) { .popup_detail .popup__course-image { width: 50%; } }

.popup_detail .popup__course-item { box-sizing: border-box; position: relative; font-size: 0; background: #fff; padding: 30px 0; margin: 10px 0; }

@media (min-width: 768px) { .popup_detail .popup__course-item { padding: 40px 0; } }

@media (min-width: 1280px) { .popup_detail .popup__course-item { max-width: 970px; margin: 15px auto; padding: 0; display: flex; align-items: stretch; justify-content: center; } }

@media (min-width: 1900px) { .popup_detail .popup__course-item { max-width: 1214px; } }

.popup_detail .popup__course-item:before, .popup_detail .popup__course-item:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.popup_detail .popup__course-item:before { top: -2px; background-image: url("../img/svg/adge_bottom_black.svg"); }

.popup_detail .popup__course-item:after { bottom: -2px; background-image: url("../img/svg/adge_top_black.svg"); }

.popup_detail .popup__course-title { font-size: 24px; margin-bottom: 5px; }

@media (min-width: 768px) { .popup_detail .popup__course-title { font-size: 31px; } }

@media (min-width: 1280px) { .popup_detail .popup__course-title { font-size: 30px; } }

@media (min-width: 1900px) { .popup_detail .popup__course-title { font-size: 40px; } }

.popup_detail .popup__course-text { font-size: 12px; color: #000; margin-bottom: 5px; }

@media (min-width: 768px) { .popup_detail .popup__course-text { font-size: 16px; } }

@media (min-width: 1280px) { .popup_detail .popup__course-text { font-size: 10px; } }

@media (min-width: 1900px) { .popup_detail .popup__course-text { font-size: 12px; } }

.popup_detail .popup__course-text span { color: #000; }

.popup_detail .popup__course-footer { margin-top: 10px; display: flex; align-items: center; justify-content: space-between; }

.popup_detail .popup__course-price-title { font-size: 12px; margin-bottom: 5px; }

@media (min-width: 768px) { .popup_detail .popup__course-price-title { font-size: 16px; } }

@media (min-width: 1280px) { .popup_detail .popup__course-price-title { font-size: 12px; } }

@media (min-width: 1900px) { .popup_detail .popup__course-price-title { font-size: 14px; } }

.popup_detail .popup__course-price-buy { font-size: 20px; color: #FF9900; font-weight: 600; }

@media (min-width: 768px) { .popup_detail .popup__course-price-buy { font-size: 26px; } }

@media (min-width: 1280px) { .popup_detail .popup__course-price-buy { font-size: 20px; } }

@media (min-width: 1900px) { .popup_detail .popup__course-price-buy { font-size: 30px; } }

.popup_detail .popup__course-price-buy span { display: none; color: #000; }

@media (min-width: 768px) { .popup_detail .popup__course-price-buy span { font-size: 12px; color: #000; display: inline-block; vertical-align: baseline; } }

@media (min-width: 1280px) { .popup_detail .popup__course-price-buy span { display: none; } }

.popup_detail .popup__coach .popup__title { padding: 0 20px; }

@media (min-width: 768px) { .popup_detail .popup__coach .popup__title { padding: 0; margin-right: auto; margin-left: auto; max-width: 615px; margin-bottom: 15px; } }

@media (min-width: 1280px) { .popup_detail .popup__coach .popup__title { max-width: 970px; font-size: 30px; margin-bottom: 20px; } }

@media (min-width: 1900px) { .popup_detail .popup__coach .popup__title { max-width: 1214px; font-size: 40px; } }

.popup_detail .popup__coach-inner { position: relative; padding: 0 20px; }

@media (min-width: 768px) { .popup_detail .popup__coach-inner { max-width: 615px; padding: 0 0 0 85px; margin: 0 auto; box-sizing: border-box; } }

@media (min-width: 1280px) { .popup_detail .popup__coach-inner { max-width: 970px; padding: 40px; width: 50%; height: 100%; } }

@media (min-width: 1900px) { .popup_detail .popup__coach-inner { max-width: 1214px; } }

.popup_detail .popup__coach-image { background: no-repeat 50% 50%; background-size: cover; }

@media (min-width: 1280px) { .popup_detail .popup__coach-image { width: 50%; } }

.popup_detail .popup__coach-item { box-sizing: border-box; position: relative; font-size: 0; background: #fff; padding: 30px 0 30px 62px; margin: 10px 0; }

@media (min-width: 768px) { .popup_detail .popup__coach-item { padding: 40px 0; } }

@media (min-width: 1280px) { .popup_detail .popup__coach-item { max-width: 970px; padding: 0; margin: 15px auto; display: flex; align-items: stretch; justify-content: center; } }

@media (min-width: 1900px) { .popup_detail .popup__coach-item { max-width: 1214px; } }

.popup_detail .popup__coach-item:before, .popup_detail .popup__coach-item:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.popup_detail .popup__coach-item:before { top: -2px; background-image: url("../img/svg/adge_bottom_black.svg"); }

.popup_detail .popup__coach-item:after { bottom: -2px; background-image: url("../img/svg/adge_top_black.svg"); }

.popup_detail .popup__coach-photo { width: 50px; height: 50px; position: absolute; top: 0px; left: -42px; overflow: hidden; }

@media (min-width: 768px) { .popup_detail .popup__coach-photo { left: 0px; width: 65px; height: 65px; } }

@media (min-width: 1280px) { .popup_detail .popup__coach-photo { display: none; } }

.popup_detail .popup__coach-photo img { width: 100%; height: 100%; }

.popup_detail .popup__coach-title { font-size: 24px; margin-bottom: 5px; }

@media (min-width: 768px) { .popup_detail .popup__coach-title { font-size: 31px; } }

@media (min-width: 1280px) { .popup_detail .popup__coach-title { font-size: 30px; } }

@media (min-width: 1900px) { .popup_detail .popup__coach-title { font-size: 40px; } }

.popup_detail .popup__coach-text { font-size: 12px; color: #000; margin-bottom: 5px; }

@media (min-width: 768px) { .popup_detail .popup__coach-text { font-size: 16px; margin-bottom: 10px; } }

@media (min-width: 1280px) { .popup_detail .popup__coach-text { font-size: 10px; } }

@media (min-width: 1900px) { .popup_detail .popup__coach-text { font-size: 12px; } }

.popup_detail .popup__form { position: relative; background: #fff; padding: 30px 0; margin-top: 30px; }

@media (min-width: 768px) { .popup_detail .popup__form { margin-top: 40px; } }

@media (min-width: 1280px) { .popup_detail .popup__form { margin-top: 50px; } }

@media (min-width: 1900px) { .popup_detail .popup__form { margin-top: 60px; } }

.popup_detail .popup__form:before, .popup_detail .popup__form:after { content: ''; display: block; position: absolute; left: 0; right: 0; height: 20px; background: repeat-x 50% 50%; background-size: auto 100%; z-index: 1; }

.popup_detail .popup__form:before { top: -2px; background-image: url("../img/svg/adge_bottom_black.svg"); }

.popup_detail .popup__form:after { bottom: -2px; background-image: url("../img/svg/adge_top_black.svg"); }

.popup_detail .popup__form .form { padding: 0 20px; margin: 0 auto; }

@media (min-width: 768px) { .popup_detail .popup__form .form { padding: 0; max-width: 615px; } }

@media (min-width: 1280px) { .popup_detail .popup__form .form { max-width: 970px; display: flex; align-items: center; justify-content: space-between; } }

@media (min-width: 1900px) { .popup_detail .popup__form .form { max-width: 1214px; } }

@media (min-width: 768px) { .popup_detail .popup__form .form__field { width: 50%; display: inline-block; vertical-align: top; padding: 0 15px; } }

@media (min-width: 768px) { .popup_detail .popup__form .form__field_submit { width: 100%; } }

@media (min-width: 1280px) { .popup_detail .popup__form .form__wrap { width: 50%; } }

.popup_detail .popup__form .form .popup__title { color: #000; text-align: center; }

@media (min-width: 1280px) { .popup_detail .popup__form .form .popup__title { font-size: 40px; line-height: 1.7; text-align: left; width: 50%; margin: 20px 0; padding-right: 30px; box-sizing: border-box; } }

.popup_detail .popup__form .form .popup__title span { color: #FF9900; }
