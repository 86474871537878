$popupZIndex: 100;
$popupOverlayColor: rgba(0, 0, 0, 0.8);

.popup {

	display: none;

	&.is-open {
		display: block !important;
	}

	&__overlay {
		background-color: $popupOverlayColor;
		display: block;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: $popupZIndex;
	}

	&__inner {
		height: 100%;
		left: 0;
		overflow: auto;
		position: fixed;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: $popupZIndex;
		display: block;
		background: transparent;
		overflow-y: scroll;
		font-size: 0;

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

	}

	&__layout {
		background-color: white;
		box-sizing: border-box;
		display: inline-block;
		margin: 0 auto;
		padding: 0;
		position: relative;
		vertical-align: middle;
		width: 100%;
		max-width: calc(100% - 40px);
		min-height: 100px;
		text-align: left;
		font-size: 16px;
		@include border-radius(4px);

		@include breakPoint($breakPoint1){
			max-width: 448px;
		}

		@include breakPoint($breakPoint2){
			max-width: 530px;
			@include border-radius(5px 0 5px 5px);
		}

		@include breakPoint($breakPoint3){
			max-width: 764px;
		}
	}

	&__close {
		fill: $colorOrange;
		position: fixed;
		width: 74px;
		height: 74px;
		top: 0px;
		right: 0px;
		overflow: hidden;
		cursor: pointer;
		z-index: 10;

		@include breakPoint($breakPoint2){
			left: 100%;
			position: absolute;
			width: 75px;
		}

		@include breakPoint($breakPoint3){
			width: 95px;
			height: 95px;
		}

		&:hover{
			fill: $colorOrangeLight;

			@include breakPoint($breakPoint2){

			}
		}


		i{
			pointer-events: none;
			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				background: #fff;
				top: 45%;
				left: 56%;
				height: 2px;
				width: 22px;
				margin-top: -2px;
				margin-left: -11px;
				border-radius: 10px;


				@include breakPoint($breakPoint2){
					top: 48%;
					left: 46%;
				}

				@include breakPoint($breakPoint3){
					top: 45%;
					left: 46%;
					width: 30px;
					height: 3px;
					margin-top: -1.5px;
					margin-left: -15px;
				}

			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}

		svg{
			pointer-events: none;
			height: 100%;
			//width: auto;
			position: absolute;
			top: -5px;
			left: -13px;
			transition: fill 0.3s ease;

			@include breakPoint($breakPoint2){
				left: auto;
				right: -13px;
			}

			@include breakPoint($breakPoint3){
				right: 0;
			}
		}


	}



	&__content{
		padding: 30px 20px;

		@include breakPoint($breakPoint1){
			padding: 40px 30px;
		}

		@include breakPoint($breakPoint2){
			padding: 50px 40px;
		}

		@include breakPoint($breakPoint3){
			padding: 60px 55px;
		}
	}


	&__title{
		@extend %fontTitle;
		font-size: 24px;
		margin-bottom: 15px;

		@include breakPoint($breakPoint1){
			font-size: 31px;
			margin-bottom: 25px;
		}

		@include breakPoint($breakPoint2){
			font-size: 50px;
			margin-bottom: 40px;
		}

		@include breakPoint($breakPoint3){
			font-size: 70px;
			margin-bottom: 50px;
		}

		span{
			color: $colorOrange;
		}
	}



	&__text{
		font-size: 12px;
		line-height: 1.5;

		@include breakPoint($breakPoint1){
			font-size: 16px;
		}

		@include breakPoint($breakPoint2){
			font-size: 12px;
		}

		@include breakPoint($breakPoint3){
			font-size: 14px;
		}
	}


	.btn{

		@include breakPoint(0, $breakPoint1 - 1px){
			font-size: 16px;
			padding: 14px 30px 17px;
			display: inline-block;
		}

	}


	&__form{
		.form{

			@include breakPoint($breakPoint2){
				margin: 0 -15px;
			}

			&__field{



				@include breakPoint($breakPoint1){
					width: 282px;
					margin: 0 auto;
				}

				@include breakPoint($breakPoint2){
					width: 50%;
					padding: 0 15px;
					display: inline-block;
					vertical-align: top;
					box-sizing: border-box;
				}


				&_submit{
					margin-top: 35px;

					@include breakPoint($breakPoint1){
						width: auto;
					}

					.btn{
						width: 135px;
						display: inline-block;
						margin-right: 5px;
						padding-left: 10px;
						padding-right: 10px;

						@include breakPoint($breakPoint1){
							width: 199px;
							margin-right: 20px;
						}

						@include breakPoint($breakPoint2){
							width: 111px;
							margin-right: 30px;
						}
						@include breakPoint($breakPoint3){
							width: 197px;
							margin-right: 60px;
						}
					}

					.footnote{
						font-size: 10px;
						line-height: 1.5;
						display: inline-block;
						vertical-align: middle;
						color: $colorGreyLight;
						width: calc(100% - 150px);
						@extend %fontProximaNova;

						@include breakPoint($breakPoint1){
							font-size: 12px;
							width: calc(100% - 225px);
						}
						@include breakPoint($breakPoint3){
							font-size: 14px;
							width: calc(100% - 285px);
						}
					}
				}
			}
		}
	}

}


.popup_order{

	.popup__title{
		text-align: center;
	}


}



.popup_info{
	.popup__layout{
		background-image: url("../img/svg/record.svg");
		background-position: 100% 100%;
		background-repeat: no-repeat;
	}
}


.popup_feedback{

	@include breakPoint($breakPoint2){
		display: none!important;
	}

	.popup__layout{
		max-width: 100%;
		@include border-radius(0);
		min-height: 100%;
	}
	.popup__content{
		padding-right: 80px;

		@include breakPoint($breakPoint1){
			padding-right: 100px;
		}
	}
}



.popup_gallery{


	.popup__overlay{
		background: #000;
	}

	.popup__layout{
		max-width: 100%;
		@include border-radius(0);
		background: transparent;

		@include breakPoint($breakPoint1){
			max-width: 615px;
		}

		@include breakPoint($breakPoint2){
			max-width: 988px;
		}

		@include breakPoint($breakPoint3){
			max-width: 1400px;
		}
	}
	.popup__content{
		padding: 0;

	}

	.popup__photo{
		position: relative;


		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_black.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_black.svg");
		}



		&-controls{

			&-arrow{

				fill: $colorGreyLight;
				display: inline-block;
				cursor: pointer;
				position: absolute;
				bottom: 20px;
				z-index: 1;

				@include breakPoint($breakPoint1){
					bottom: 50%;
					margin-bottom: -14px;
				}

				&:hover{
					fill: $colorOrange;
				}

				svg{
					width: 22px;
					height: 22px;
					position: relative;

					@include breakPoint($breakPoint1){
						width: 28px;
						height: 28px;
					}

					@include breakPoint($breakPoint3){
						width: 36px;
						height: 36px;
					}

				}

				&_prev{

					left: 20px;

					@include breakPoint($breakPoint1){
						left: -50px;
					}

					svg {
						transform: rotate(-90deg);
					}
				}

				&_next{

					right: 20px;

					@include breakPoint($breakPoint1){
						right: -50px;
					}

					svg {
						transform: rotate(90deg);
					}
				}

				&.slick-disabled{
					opacity: 0.5;
					cursor: default;
					fill: $colorGreyLight;
				}
			}
		}

		&-item{
			vertical-align: middle;

			img{
				max-width: 100%;
				max-height: calc(100vh - 100px);
				display: block;
				margin: 0 auto;
			}
		}
	}

	.popup__inner{
		overflow: auto;
	}

	.popup__close {

		@include breakPoint($breakPoint2){
			position: fixed;
			left: auto;
		}

		@include breakPoint($breakPoint3){
			width: 95px;
			height: 95px;
		}

		&:hover{
			fill: $colorOrangeLight;
		}


		i{

			&:before,
			&:after {

				@include breakPoint($breakPoint2){
					top: 45%;
					left: 56%;
				}

			}

		}

		svg{
			left: -13px;
			right: auto;
		}
	}
}



.popup_detail{
	.popup__close {

		@include breakPoint($breakPoint2){
			position: fixed;
			left: auto;
		}

		@include breakPoint($breakPoint3){
			width: 95px;
			height: 95px;
		}

		&:hover{
			fill: $colorOrangeLight;
		}


		i{

			&:before,
			&:after {

				@include breakPoint($breakPoint2){
					top: 45%;
					left: 56%;
				}

			}

		}

		svg{
			left: -13px;
			right: auto;

			@include breakPoint($breakPoint3){
				left: 0px;
			}
		}
	}

	.popup__overlay{
		background: #000;
	}

	.popup__layout{
		min-height: 100%;
		min-width: 100%;
		background: transparent;
		padding: 30px 0;
	}


	.popup__photo{
		position: relative;
		margin-bottom: 20px;


		&-controls{

			&-arrow{

				fill: $colorGreyLight;
				display: inline-block;
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				z-index: 1;

				@include breakPoint($breakPoint1){
					bottom: 50%;
					margin-bottom: -14px;
				}

				&:hover{
					fill: $colorOrange;
				}

				svg{
					width: 22px;
					height: 22px;
					position: relative;

					@include breakPoint($breakPoint1){
						width: 28px;
						height: 28px;
					}

					@include breakPoint($breakPoint3){
						width: 36px;
						height: 36px;
					}

				}

				&_prev{

					left: 0px;

					@include breakPoint($breakPoint1){
						left: -50px;
					}

					svg {
						transform: rotate(-90deg);
					}
				}

				&_next{

					right: 0px;

					@include breakPoint($breakPoint1){
						right: -50px;
					}

					svg {
						transform: rotate(90deg);
					}
				}

				&.slick-disabled{
					opacity: 0.5;
					cursor: default;
					fill: $colorGreyLight;
				}
			}
		}

		&-item{
			vertical-align: middle;
			position: relative;

			&:before,
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				height: 20px;
				background: repeat-x 50% 50%;
				background-size: auto 100%;
				z-index: 1;
			}
			&:before{
				top: -2px;
				background-image: url("../img/svg/adge_bottom_black.svg");
			}
			&:after{
				bottom: -2px;
				background-image: url("../img/svg/adge_top_black.svg");
			}


			img{
				display: block;
				margin: 0 auto;
				max-height: calc(100vh - 230px);
				width: auto;
				max-width: 100%;
			}
		}

		.slick-dots{
			text-align: center;
			top: 100%;
			left: 35px;
			right: 35px;

			li{
				display: inline-block;
				vertical-align: middle;
				fill: $colorGreyLight;
				cursor: pointer;
				margin: 10px 5px;

				&.slick-active{
					fill: $colorOrange;
				}
			}

			button{
				display: block;
			}

			svg{
				width: 20px;
				height: 20px;
				display: block;
			}
		}
	}

	.popup__title{
		color: $colorOrange;
		margin-top: 30px;

		@include breakPoint($breakPoint1){
			//font-size: 31px;
		}
	}

	.popup__text{
		color: #fff;
	}


	.popup__content{
		position: relative;
		padding: 0 20px;

		@include breakPoint($breakPoint1){
			padding: 0;
			max-width: 615px;
			margin: 0 auto;
		}

		@include breakPoint($breakPoint2){
			max-width: 970px;
		}

		@include breakPoint($breakPoint3){
			max-width: 1214px;
		}
	}




	.popup__course{

		.popup__title{
			padding: 0 20px;

			@include breakPoint($breakPoint1){
				padding: 0;
				margin-right: auto;
				margin-left:  auto;
				max-width: 615px;
				margin-bottom: 15px;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;
				font-size: 30px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
				font-size: 40px;
			}
		}

		&-inner{
			position: relative;
			padding: 0 20px;

			@include breakPoint($breakPoint1){
				max-width: 615px;
				padding: 0;
				margin: 0 auto;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;
				width: 50%;
				height: 100%;
				padding: 40px 30px;
				box-sizing: border-box;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
			}
		}

		&-image{
			background: no-repeat 50% 50%;
			background-size: cover;

			@include breakPoint($breakPoint2){
				width: 50%;
			}
		}

		&-item{

			box-sizing: border-box;
			position: relative;
			font-size: 0;
			background: #fff;
			padding: 30px 0;
			margin: 10px 0;

			@include breakPoint($breakPoint1){
				padding: 40px 0;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;
				margin: 15px auto;
				padding: 0;
				display: flex;
				align-items: stretch;
				justify-content: center;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
			}

			&:before,
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				height: 20px;
				background: repeat-x 50% 50%;
				background-size: auto 100%;
				z-index: 1;
			}
			&:before{
				top: -2px;
				background-image: url("../img/svg/adge_bottom_black.svg");
			}
			&:after{
				bottom: -2px;
				background-image: url("../img/svg/adge_top_black.svg");
			}
		}

		&-title{
			font-size: 24px;
			@extend %fontTitle;
			margin-bottom: 5px;

			@include breakPoint($breakPoint1){
				font-size: 31px;
			}

			@include breakPoint($breakPoint2){
				font-size: 30px;
			}

			@include breakPoint($breakPoint3){
				font-size: 40px;
			}
		}
		&-text{
			font-size: 12px;
			color: $colorGrey;
			margin-bottom: 5px;

			@include breakPoint($breakPoint1){
				font-size: 16px;
			}

			@include breakPoint($breakPoint2){
				font-size: 10px;
			}

			@include breakPoint($breakPoint3){
				font-size: 12px;
			}

			span{
				color: #000;
			}
		}
		&-footer{
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&-price{

			@extend %fontRobotoSlab;

			&-title{
				font-size: 12px;
				margin-bottom: 5px;

				@include breakPoint($breakPoint1){
					font-size: 16px;
				}

				@include breakPoint($breakPoint2){
					font-size: 12px;
				}

				@include breakPoint($breakPoint3){
					font-size: 14px;
				}
			}
			&-buy{
				font-size: 20px;
				color: $colorOrange;
				font-weight: 600;

				@include breakPoint($breakPoint1){
					font-size: 26px;
				}

				@include breakPoint($breakPoint2){
					font-size: 20px;
				}

				@include breakPoint($breakPoint3){
					font-size: 30px;
				}

				span{
					display: none;
					@extend %fontProximaNova;
					color: $colorGrey;


					@include breakPoint($breakPoint1){
						font-size: 12px;
						color: $colorGrey;
						display: inline-block;
						vertical-align: baseline;
					}

					@include breakPoint($breakPoint2){
						display: none;
					}
				}
			}
		}
		&-btn{}
	}


	.popup__coach{


		.popup__title{
			padding: 0 20px;

			@include breakPoint($breakPoint1){
				padding: 0;
				margin-right: auto;
				margin-left:  auto;
				max-width: 615px;
				margin-bottom: 15px;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;
				font-size: 30px;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
				font-size: 40px;
			}
		}


		&-inner{
			position: relative;
			padding: 0 20px;

			@include breakPoint($breakPoint1){
				max-width: 615px;
				padding: 0 0 0 85px;
				margin: 0 auto;
				box-sizing: border-box;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;
				padding: 40px;
				width: 50%;
				height: 100%;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
			}
		}

		&-image{
			background: no-repeat 50% 50%;
			background-size: cover;

			@include breakPoint($breakPoint2){
				width: 50%;
			}
		}

		&-item{

			box-sizing: border-box;
			position: relative;
			font-size: 0;
			background: #fff;
			padding: 30px 0 30px 62px;
			margin: 10px 0;

			@include breakPoint($breakPoint1){
				padding: 40px 0;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;
				padding: 0;
				margin: 15px auto;
				display: flex;
				align-items: stretch;
				justify-content: center;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
			}

			&:before,
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				height: 20px;
				background: repeat-x 50% 50%;
				background-size: auto 100%;
				z-index: 1;
			}
			&:before{
				top: -2px;
				background-image: url("../img/svg/adge_bottom_black.svg");
			}
			&:after{
				bottom: -2px;
				background-image: url("../img/svg/adge_top_black.svg");
			}
		}

		&-photo{
			width: 50px;
			height: 50px;
			position: absolute;
			top: 0px;
			left: -42px;
			overflow: hidden;
			@include border-radius();

			@include breakPoint($breakPoint1){
				left: 0px;
				width: 65px;
				height: 65px;
			}
			@include breakPoint($breakPoint2){
				display: none;
			}

			img{
				width: 100%;
				height: 100%;
			}
		}

		&-title{
			font-size: 24px;
			margin-bottom: 5px;
			@extend %fontTitle;

			@include breakPoint($breakPoint1){
				font-size: 31px;
			}

			@include breakPoint($breakPoint2){
				font-size: 30px;
			}

			@include breakPoint($breakPoint3){
				font-size: 40px;
			}
		}

		&-text{
			font-size: 12px;
			color: $colorGrey;
			margin-bottom: 5px;

			@include breakPoint($breakPoint1){
				font-size: 16px;
				margin-bottom: 10px;
			}

			@include breakPoint($breakPoint2){
				font-size: 10px;
			}

			@include breakPoint($breakPoint3){
				font-size: 12px;
			}
		}

		&-btn{

		}
	}

	.popup__form{
		position: relative;
		background: #fff;
		padding: 30px 0;
		margin-top: 30px;

		@include breakPoint($breakPoint1){
			margin-top: 40px;
		}

		@include breakPoint($breakPoint2){
			margin-top: 50px;
		}

		@include breakPoint($breakPoint3){
			margin-top: 60px;
		}

		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background: repeat-x 50% 50%;
			background-size: auto 100%;
			z-index: 1;
		}
		&:before{
			top: -2px;
			background-image: url("../img/svg/adge_bottom_black.svg");
		}
		&:after{
			bottom: -2px;
			background-image: url("../img/svg/adge_top_black.svg");
		}


		.form{
			padding: 0 20px;
			margin: 0 auto;

			@include breakPoint($breakPoint1){
				padding: 0;
				max-width: 615px;
			}

			@include breakPoint($breakPoint2){
				max-width: 970px;

				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			@include breakPoint($breakPoint3){
				max-width: 1214px;
			}

			&__field{

				@include breakPoint($breakPoint1){
					width: 50%;
					display: inline-block;
					vertical-align: top;
					padding: 0 15px;
				}

				&_submit{

					@include breakPoint($breakPoint1){
						width: 100%;
					}
				}
			}

			&__wrap{

				@include breakPoint($breakPoint2){
					width: 50%;
				}
			}

			.popup__title{
				color: #000;
				text-align: center;

				@include breakPoint($breakPoint2){
					font-size: 40px;
					line-height: 1.7;
					text-align: left;
					width: 50%;
					margin: 20px 0;
					padding-right: 30px;
					box-sizing: border-box;
				}

				span{
					color: $colorOrange;
				}
			}
		}
	}


	.btn{


		@include breakPoint($breakPoint1, $breakPoint2 - 1px){

		}
	}
}