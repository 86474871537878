
$fieldHeight: $formHeight;
$borderRadius: 0;
$inputPadding: 0 0;


.placeholder{
	position: absolute;
	color: $colorBlack;
	top: 0;
	right: 100%;
	left: 0;
	text-align: right;
	line-height: $fieldHeight;
	cursor: default;
	pointer-events: none;

	white-space: nowrap;
	@include box-sizing();
	@include transition(all 0.3s ease);
	padding: $inputPadding;

	.f-error &{
		color: $colorRed;
	}

	input:focus ~ &,
	textarea:focus ~ &,
	.f-filled &{
		right: 0;
		font-size: 12px;
	}
}



.form{
	font-size: 0;

	&__field{
		position: relative;
		margin-bottom: 30px;
		font-size: 16px;
		box-sizing: border-box;

		@include breakPoint($breakPoint1){
			font-size: 18px;
			margin-bottom: 40px;
		}

		@include breakPoint($breakPoint2){
			font-size: 14px;
		}

		@include breakPoint($breakPoint3){
			font-size: 16px;
		}

		&:last-child{
			margin-bottom: 0;
		}

		&.f-error{

			input{
				&[type="email"],
				&[type="password"],
				&[type="tel"],
				&[type="text"]{
					border-color: $colorRed!important;
					border-bottom-style: solid;
				}
			}
		}

	}

	&__message{
		display: none;
		top: 8px;
		font-size: 12px;
		position: relative;
		//padding: $inputPadding;

		.f-message &{
			display: block;
		}

		.f-error &{
			color: $colorRed;
		}
	}

	&__label{
		position: relative;
	}

	&__input{
		position: relative;
		@extend %fontRobotoSlab;
		color: $colorGrey;

		textarea, input{

		}


		input{

			&[type="email"],
			&[type="password"],
			&[type="tel"],
			&[type="text"]{
				@include box-sizing();
				width: 100%;
				display: block;
				color: $colorBlack;
				line-height: ($fieldHeight - 2px);
				height: $fieldHeight;
				border-bottom: 2px dashed $colorGreyLight;
				@include border-radius($borderRadius);
				background: #fff;
				@include transition('border 0.3s ease');
				padding: $inputPadding;

				@include breakPoint($breakPoint1){
					line-height: (60px - 2px);
					height: 60px;
				}

				@include breakPoint($breakPoint2){
					line-height: (50px - 2px);
					height: 50px;
				}

				.f-filled{
					border-bottom-style: solid;
					border-bottom-color: $colorGreyLight;
				}

				&:focus{
					border-bottom-style: solid;
					border-bottom-color: $colorGrey;
				}

				.f-error &{
					border-color: $colorRed;
					background: url("../img/svg/fail.svg") no-repeat 100% 50%;
					background-size: 25px;
				}

				&:hover{
				}
			}


			&[type="submit"]{
				@include box-sizing();
				color: #fff;
				cursor: pointer;
				width: 100%;
				display: block;

				&:not(.btn){
					line-height: $fieldHeight;
					@include border-radius($borderRadius);
				}

				&:hover{
				}
			}

			&[type="checkbox"]{
				opacity: 0;
				position: absolute;

				&:checked{
					& ~ {
						.form__label{

							&:before{
								background-color: green;
							}
						}
					}
				}

				& ~ {
					.form__label{
						padding: 7px 0 7px 42px;

						&:before{
							content: '';
							width: 28px;
							height: 28px;
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							@include border-radius($borderRadius);
							background: #fff;
							border: 1px solid #000;

							.f-error &{
								border-color: $colorRed;
								border-bottom-style: solid;
							}
						}
					}
				}
			}

			&[type="radio"]{
				opacity: 0;
				position: absolute;

				&:checked{
					& ~ {
						.form__label{

							&:before{
								background-color: green;
							}
						}
					}
				}

				& ~ {
					.form__label{
						padding: 7px 0 7px 42px;

						&:before{
							content: '';
							width: 28px;
							height: 28px;
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							@include border-radius(100px);
							background: #fff;
							border: 1px solid #000;

							.f-error &{
								border-color: $colorRed;
							}
						}
					}
				}
			}
		}

	}
}


